import React from 'react';
import MenuBar from './../components/Menubar';

type Props = { 
	user: any
};
	
const TermsPage: React.FC<Props> = (props) => {	
	return (
		<main className="main-bg terms-bg">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="about-content terms-content">  
						<div className="terms-box bg-white"> 
							<h3 className="text-center lulo-bold about-title">YOLO MEAL TERMS OF USE</h3>
							<p>
									<strong>Yolomeal Terms of Service</strong>
							</p>
							<p>
									<em>Last Updated: August 26, 2021</em>
							</p>
							<p>
									Welcome to Yolomeal! Yolomeal is a platform that provides you with the
									easiest way to find the best tasting food at local restaurants.
							</p>
							<p>
									These terms of service (“Terms of Service”) set forth the agreement
									(“Agreement”) between you and Yolomeal, Inc. (“Yolomeal” “we” or “us”). It
									governs your use of the products and services we offer through our websites
									and applications (collectively the “Yolomeal Platform”).
							</p>
							<p>
									Please make sure to read it, because, by using the Yolomeal Platform, you
									consent to these Terms of Service.
							</p>
							<p>
									1.
									<strong>
											The Mission of the Yolomeal Platform
											<br/>
									</strong>
									<br/>
									<strong>
											<em>
													Yolomeal's mission is to welcome the power of the crowd in making
													all our lives better by sharing and discovering the best tasting
													food wherever in the world we go.
											</em>
									</strong>
							</p>
							<p>
									2. <strong>Using the Yolomeal Platform</strong>
							</p>
							<p>
									a. <strong>Who Can Use It</strong>. Use of the Yolomeal Platform by anyone
									under 13 years of age is prohibited. You represent that you are at least
									the age of majority in the jurisdiction where you live or, if you are not,
									your parent or legal guardian must consent to these Terms of Service and
									affirm that they accept this Agreement on your behalf and bear
									responsibility for your use. If you are accepting these Terms of Service on
									behalf of someone else or an entity, you confirm that you have the legal
									authority to bind that person or entity to this Agreement.
							</p>
							<p>
									b. <strong>Registration</strong>. When you set up a profile on the Yolomeal
									Platform, you will be asked to provide certain information about yourself.
									You agree to provide us accurate information when you create your account
									on the Yolomeal Platform. We will treat information you provide as part of
									registration in accordance with our <em><u>Privacy Policy</u></em> You
									should take care in maintaining the confidentiality of your password.
							</p>
							<p>
									c. <strong>Privacy Policy</strong>. Our privacy practices are set forth in
									our <em><u>Privacy Policy</u></em>. By use of the Yolomeal Platform, you
									agree to accept our <em><u>Privacy Policy</u></em>, regardless of whether
									you are a registered user.
							</p>
							<p>
									<strong>Acceptable Use Policy</strong>
									. In your interaction with others on the Yolomeal Platform, you agree to
									follow our Acceptable Use Policy:
							</p>
							<p>
									<em>
											No Spam
											<br/>
									</em>
									Do not use Yolomeal to post or attract spam.
							</p>
							<p>
									<em>
											No Malicious or Illegal Activity
											<br/>
									</em>
									Do not contribute viruses, malware, and other malicious programs or engage
									in activity that harms or interferes with the operation of Yolomeal or
									seeks to circumvent security or authentication measures. Do not use
									Yolomeal to engage in unlawful activity or to promote illegal acts.
									<br/>
									<br/>
									<em>
											Abide by Other Yolomeal Policies
											<br/>
											<br/>
									</em>
							</p>
							<p>
									<strong>Reporting Problems</strong>
							</p>
							<p>
									If you see something on Yolomeal that you believe violates our guidelines
									or policies, please report it to us via email: <a href="mailto:info@yolomeal.com">info@yolomeal.com</a>.
									<br/>
									<br/>
									Violating the Acceptable Use Policy may result in the removal of content,
									or in the limitation or termination of a user's access to Yolomeal.
							</p>
							<p>
									d. <strong>Termination</strong>. You may close your account at any time by
									going to account settings and disabling your account. We may terminate or
									suspend your Yolomeal account if you violate any Yolomeal policy or for any
									other reason.
							</p>
							<p>
									e. <strong>Changes to the Yolomeal Platform</strong>. We are always trying
									to improve your experience on the Yolomeal Platform. We may need to add or
									change features and may do so without notice to you.
							</p>
							<p>
									f. <strong>Feedback</strong>. We welcome your feedback and suggestions
									about how to improve the Yolomeal Platform. Feel free to submit feedback by
									email: <a href="mailto:info@yolomeal.com">info@yolomeal.com</a>. By submitting feedback, you agree to grant us the
									right, at our discretion, to use, disclose and otherwise exploit the
									feedback, in whole or part, freely and without compensation to you.
							</p>
							<p>
									3. <strong>Your Content</strong>
							</p>
							<p>
									a. <strong>Definition of Your Content</strong>. The Yolomeal Platform
									enables you to add comments and photos and other information about yourself
									to share with others. All material that you upload, publish or display to
									others via the Yolomeal Platform will be referred to collectively as “Your
									Content.” You acknowledge and agree that, as part of using the Yolomeal
									Platform, Your Content may be viewed by the general public.
							</p>
							<p>
									b.
									<strong>
											Ownership. You, or your licensors, as applicable, retain ownership of
											the copyright and other intellectual property in Your Content, subject
											to the non-exclusive rights granted below.
									</strong>
							</p>
							<p>
									c. <strong>License and Permission to Use Your Content.</strong>
							</p>
							<p>
									i. By submitting, posting, or displaying Your Content on the Yolomeal
									Platform, you grant Yolomeal and its affiliated companies a nonexclusive,
									worldwide, royalty free, fully paid up, transferable, sublicensable
									(through multiple tiers), license to use, copy, reproduce, process, adapt,
									modify, create derivative works from, publish, transmit, store, display and
									distribute, translate, communicate and make available to the public, and
									otherwise use Your Content in connection with the operation or use of the
									Yolomeal Platform or the promotion, advertising or marketing of the
									Yolomeal Platform or our business partners, in any and all media or
									distribution methods (now known or later developed), including via means of
									automated distribution, such as through an application programming
									interface (also known as an “API”). You agree that this license includes
									the right for Yolomeal to make Your Content available to other companies,
									organizations, business partners, or individuals who collaborate with
									Yolomeal for the syndication, broadcast, communication and making available
									to the public, distribution or publication of Your Content on the Yolomeal
									Platform or through other media or distribution methods. This license also
									includes the right for other users of the Yolomeal Platform to use, copy,
									reproduce, adapt, modify, create derivative works from, publish, transmit,
									display, and distribute, translate, communicate and make available to the
									public Your Content, subject to our Terms of Service. Except as expressly
									provided in these Terms of Service, this license will not confer the right
									for you to use automated technology to copy content displayed on
									Yolomeal.com for the purpose of making derivative works.
							</p>
							<p>
									ii. Any edits and changes made by you may be visible to other users. The
									right for Yolomeal to copy, display, transmit, publish, perform,
									distribute, store, modify, and otherwise use any information you post, and
									sublicense those rights to others, is perpetual and irrevocable, to the
									maximum extent permitted by law, except as otherwise specified in this
									Agreement.
							</p>
							<p>
									iii. You acknowledge and agree that Yolomeal may preserve Your Content and
									may also disclose Your Content and related information if required to do so
									by law or in the good faith belief that such preservation or disclosure is
									reasonably necessary to: (a) comply with legal process, applicable laws or
									government requests; (b) enforce these Terms of Service; (c) respond to
									claims that any of Your Content violates the rights of third parties; (d)
									detect, prevent, or otherwise address fraud, security or technical issues;
									or (e) protect the rights, property, or personal safety of Yolomeal, its
									users, or the public.
							</p>
							<p>
									iv. You understand that we may modify, adapt, or create derivative works
									from Your Content in order to transmit, display or distribute it over
									computer networks, devices, service providers, and in various media. We
									also may remove or refuse to publish Your Content, in whole or part, at any
									time.
							</p>
							<p>
									v. You further give us the permission and authority to act as your
									nonexclusive agent to take enforcement action against any unauthorized use
									by third parties of any of Your Content outside of the Yolomeal Platform or
									in violation of our Terms of Service.
							</p>
							<p>
									d. <strong>Your Responsibilities for Your Content</strong>. By posting Your
									Content on the Yolomeal Platform, you represent and warrant to us that: i)
									you have the ownership rights, or you have obtained all necessary licenses
									or permissions to use Your Content and grant us the rights to use Your
									Content as provided for under this Agreement, and ii) that posting Your
									Content violates no intellectual property or personal right of others or
									any applicable law or regulation, including any laws or regulations
									requiring disclosure that you have been compensated for Your Content. You
									accept full responsibility for avoiding infringement of the intellectual
									property or personal rights of others or violation of laws and regulations
									in connection with Your Content. You are responsible for ensuring that Your
							Content does not violate Yolomeal’s <em><u>Acceptable Use Policy</u></em>,    <em><u>Copyright Policy</u></em> , <em><u>Trademark Policy</u></em>, other
									published Yolomeal policy, or any applicable law or regulation. You agree
									to pay all royalties, fees, and any other monies owed to any person by
									reason of Your Content.
							</p>
							<p>
									4. <strong>Our Content and Materials</strong>
							</p>
							<p>
									a. <strong>Definition of Our Content and Materials</strong>. All
									intellectual property in or related to the Yolomeal Platform (specifically
									including, but not limited to our software, the Yolomeal marks, the
									Yolomeal logo, but excluding Your Content) is the property of Yolomeal,
									Inc., or its subsidiaries and affiliates, or content posted by other
									Yolomeal users licensed to us (collectively “Our Content and Materials”).
							</p>
							<p>
									b. <strong>Data</strong>. All data Yolomeal collects (“Data”) about use of
									the Yolomeal Platform by you or others is the property of Yolomeal, Inc.,
									its subsidiaries, and affiliates. For clarity, Data does not include Your
									Content and is separate from Our Content and Materials.
							</p>
							<p>
									c. <strong>Our License to You</strong>.
							</p>
							<p>
									i. We grant you a personal, limited, non-exclusive license to use and
									access Our Content and Materials and Data as made available to you on the
									Yolomeal Platform in connection with your use of the Yolomeal Platform,
									subject to the terms and conditions of this Agreement.
							</p>
							<p>
									ii. Yolomeal gives you a worldwide, royalty-free, revocable, personal,
									non-assignable and non-exclusive license to re-post a small portion of
									content posted by other Yolomeal users anywhere on the web, subject to
									these Terms and Conditions and provided that: (a) the content in question
									was added to the Yolomeal Platform after April 22, 2010; (b) the user who
									created the content has not explicitly marked the content as not for
									reproduction on the Yolomeal Platform; (c) you do not modify the content;
									(d) you attribute Yolomeal by name in readable text and with a human and
									machine-followable link (an HTML &lt;a&gt; anchor tag) linking back to the
							page displaying the original source of the content on    <em><u>https://yolomeal.com</u></em> on every page that contains such
									content; (e) upon request, either by Yolomeal or a user, you remove the
									user's name from content which the user has subsequently made anonymous;
									(f) upon request, either by Yolomeal or by a user who contributed to the
									content, you make a reasonable effort to update a particular piece of
									content to the latest version on the Yolomeal Platform; and (g) upon
									request, either by Yolomeal or by a user who contributed to the content,
									you make a reasonable attempt to delete content that has been deleted or
									marked as not for reproduction on the Yolomeal Platform; and (h) you do not
									use any automated tools to aggregate or create derivative works. In
									exercising these rights, you may not implicitly or explicitly assert any
									connection with, sponsorship or endorsement by Yolomeal, or any Yolomeal
									user, without separate, express prior written permission from us.
							</p>
							<p>
									iii. We may terminate our license to you at any time for any reason. We
									have the right but not the obligation to refuse to distribute any content
									on the Yolomeal Platform or to remove content. Except for the rights and
									license granted in these Terms of Service, we reserve all other rights and
									grant no other rights or licenses, implied or otherwise.
							</p>
							<p>
									d. <strong>Permitted uses</strong>. If you operate a search engine, web
									crawler, bot, scraping tool, data mining tool, bulk downloading tool, wget
									utility, or similar data gathering or extraction tool, you may access the
									Yolomeal Platform, subject to the following additional rules: i) you must
									use a descriptive user agent header; ii) you must follow robots.txt at all
									times; iii) your access must not adversely affect any aspect of the
									Yolomeal Platform’s functioning; and iv) you must make it clear how to
									contact you, either in your user agent string, or on your website if you
									have one. You represent and warrant that you will not use any automated
									tools such as artificial intelligence or machine learning i) to create
									derivative works of Our Content and Materials; ii) to create any service
									competitive to the Yolomeal Platform; or iii) for other commercial purposes
									except as expressly permitted by these Terms of Service or the written
									consent of Yolomeal.
							</p>
							<p>
									e. <strong>No Endorsement or Verification</strong>. Please note that the
									Yolomeal Platform contains access to third-party content, products and
									services, and it offers interactions with third parties. Participation or
									availability on the Yolomeal Platform does not amount to endorsement or
									verification by us. We make no warranties or representations about the
									accuracy, completeness, or timeliness of any content posted on the Yolomeal
									Platform by anyone.
							</p>
							<p>
									f. <strong>Ownership</strong>. You acknowledge and agree that Our Content
									and Materials remain the property of Yolomeal's users or Yolomeal. The
									content, information and services made available on the Yolomeal Platform
									are protected by U.S. and international copyright, trademark, and other
									laws, and you acknowledge that these rights are valid and enforceable.
							</p>
							<p>
									5. <strong>Integrated Service Provider</strong>. You may enable another
									online service provider, such as a social networking service (“Integrated
									Service Provider”), to be directly integrated into your account on the
									Yolomeal Platform. By enabling an integrated service, you are allowing us
									to pass to, and receive from, the Integrated Service Provider your log-in
									information and other user data. For more information about Yolomeal’s use,
									storage, and disclosure of information related to you and your use of
							integrated services within Yolomeal, please see our    <em><u>Privacy Policy</u></em>. Note that your use of any Integrated
									Service Provider and its own handling of your data and information is
									governed solely by their terms of use, privacy policies, and other
									policies.
							</p>
							<p>
									6. <strong>More About Certain Offerings on the Yolomeal Platform</strong>
							</p>
							<p>
									a. <strong>Advertisements</strong>. The Yolomeal Platform may include
									advertisements, which may be targeted to content or information on the
									Yolomeal Platform, queries made through the Yolomeal Platform, or other
									information, in an effort to make them relevant to you. The types and
									extent of advertising by Yolomeal are subject to change. In consideration
									for Yolomeal granting you access to and use of the Yolomeal Platform, you
									agree that Yolomeal and its third-party providers and partners may place
									such advertising on the Yolomeal Platform. If you wish to become an
									advertiser, you will be required to enter into separate and supplemental
									terms with us about providing advertising services on the Yolomeal
									Platform.
							</p>
							<p>
									b. <strong>Subscriptions. </strong>Certain content from other areas of the
									Yolomeal Platform may be available only by subscription, subject to
									supplemental terms and conditions.
							</p>
							<p>
									c. <strong>Buttons, Links and Widgets</strong>. You have permission to use
									Yolomeal's buttons, links, and widgets, subject to these Terms of Service
									(including the disclaimers and limitations of liability) and provided that:
									(a) your use of such buttons, links and widgets link only to the Yolomeal
									Platform; (b) you will not modify such buttons, links, or widgets or
									associated code in any manner; (c) you will not use any such buttons,
									links, or widgets in any manner which implies or suggests that Yolomeal
									endorses, sponsors, or recommends the website on which such buttons, links
									and widgets are used; and (d) the use of such buttons, links and widgets,
									and the website on which such buttons, links and widgets are used do not
									violate Yolomeal’s <em><u>Acceptable Use Policy</u></em>.
							</p>
							<p>
									d. <strong>Web resources and third-party services</strong>. The Yolomeal
									Platform may also offer you the opportunity to visit links to other
									websites or to engage with third-party products or services. You assume all
									risk arising out of your use of such websites or resources.
							</p>
							<p>
									e. <strong>Services that Require Separate Agreement</strong>. Certain
									features or services may require that you enter into a separate and
									supplemental written agreement prior to use.
							</p>
							<p>
									7.
									<strong>
											Reporting Violations of Your Intellectual Property Rights, Yolomeal
											Policies, or Applicable Laws
									</strong>
									. We have a special process for reporting violations of your intellectual
									property rights or other violations of Yolomeal policies or applicable
									laws.
							</p>
							<p>
									a. <strong>Copyright Policy and Trademark Policy</strong>. We have adopted
							and implemented a <em><u>Copyright Policy</u></em> and    <em><u>Trademark Policy</u></em>. For more information, including detailed
									information about how to submit a request for takedown if you believe
									content on the Yolomeal Platform infringes your intellectual property
									rights, please contact us at: <a href="mailto:info@yolomeal.com">info@yolomeal.com</a>
							</p>
							<p>
									b. <strong>Reports of Other Violations</strong>. If you believe content on
							the Yolomeal Platform violates Yolomeal’s    <em><u>Acceptable Use Policy</u></em> or otherwise violates applicable law
									(apart from copyright or trademark violations) or other Yolomeal policies,
									you may contact us: <a href="mailto:info@yolomeal.com">info@yolomeal.com</a>
							</p>
							<p>
									<br/>
									<br/>
									<strong></strong>
							</p>
							<p>
									<strong>
											DISCLAIMERS AND LIMITATION OF LIABILITY
											<br/>
									</strong>
									<br/>
									<strong>
											PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF
											YOLOMEAL ENTITIES TO YOU.
											<br/>
									</strong>
									<br/>
									“YOLOMEAL ENTITIES” MEANS YOLOMEAL, INC., AND ANY SUBSIDIARIES, AFFILIATES,
									RELATED COMPANIES, SUPPLIERS, LICENSORS AND PARTNERS, AND THE OFFICERS,
									DIRECTORS, EMPLOYEES, AGENTS AND REPRESENTATIVES OF EACH OF THEM. EACH
									PROVISION BELOW APPLIES TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
									LAW.
							</p>
							<p>
									c. WE ARE PROVIDING YOU THE YOLOMEAL PLATFORM, ALONG WITH OUR CONTENT AND
									MATERIALS AND THE OPPORTUNITY TO CONNECT WITH OTHERS, ON AN “AS IS” AND “AS
									AVAILABLE” BASIS, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WITHOUT
									LIMITING THE FOREGOING, YOLOMEAL ENTITIES EXPRESSLY DISCLAIM ANY AND ALL
									WARRANTIES AND CONDITIONS OF MERCHANTABILITY, TITLE, ACCURACY AND
									COMPLETENESS, UNINTERRUPTED OR ERROR-FREE SERVICE, FITNESS FOR A PARTICULAR
									PURPOSE, QUIET ENJOYMENT, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT
									OF COURSE OF DEALING OR TRADE USAGE.
							</p>
							<p>
									d. YOLOMEAL MAKES NO PROMISES WITH RESPECT TO, AND EXPRESSLY DISCLAIMS ALL
									LIABILITY FOR: (i) CONTENT POSTED BY ANY USER OR THIRD PARTY; (ii) ANY
									THIRD-PARTY WEBSITE, THIRD-PARTY PRODUCT, OR THIRD-PARTY SERVICE LISTED ON
									OR ACCESSIBLE TO YOU THROUGH THE YOLOMEAL PLATFORM, INCLUDING AN INTEGRATED
									SERVICE PROVIDER OR PROFESSIONAL CONTRIBUTOR; (iii) THE QUALITY OR CONDUCT
									OF ANY THIRD PARTY YOU ENCOUNTER IN CONNECTION WITH YOUR USE OF THE
									YOLOMEAL PLATFORM; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
									CONTENT. YOLOMEAL MAKES NO WARRANTY THAT: (a) THE YOLOMEAL PLATFORM WILL
									MEET YOUR REQUIREMENTS; (b) THE YOLOMEAL PLATFORM WILL BE UNINTERRUPTED,
									TIMELY, SECURE, OR ERROR-FREE; (c) THE RESULTS OR INFORMATION THAT YOU MAY
									OBTAIN FROM THE USE OF THE YOLOMEAL PLATFORM, A PROFESSIONAL CONTRIBUTOR,
									OR ANY OTHER USER WILL BE ACCURATE OR RELIABLE; OR (d) THE QUALITY OF ANY
									PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED OR PURCHASED BY
									YOU THROUGH THE YOLOMEAL PLATFORM WILL BE SATISFACTORY.
							</p>
							<p>
									e. YOU AGREE THAT TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOLOMEAL ENTITIES
									WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF LIABILITY. WITHOUT LIMITING
									THE FOREGOING, YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW,
									YOLOMEAL ENTITIES SPECIFICALLY WILL NOT BE LIABLE FOR ANY INDIRECT,
									INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, LOSS OF PROFITS,
									BUSINESS INTERRUPTION, REPUTATIONAL HARM, OR LOSS OF DATA (EVEN IF WE HAVE
									BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES ARE
									FORESEEABLE) ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF, OR
									INABILITY TO USE, THE YOLOMEAL PLATFORM.
							</p>
							<p>
									f. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE YOLOMEAL PLATFORM IS TO
									STOP USING THE YOLOMEAL PLATFORM.
							</p>
							<p>
									g. WITHOUT LIMITING THE FOREGOING, YOLOMEAL’S MAXIMUM AGGREGATE LIABILITY
									TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE
									YOLOMEAL PLATFORM OR THIS AGREEMENT IS LIMITED TO THE AMOUNT PAID TO
									YOLOMEAL IN CONNECTION WITH THE YOLOMEAL PLATFORM IN THE TWELVE (12) MONTHS
									PRIOR TO THE ACTION GIVING RISE TO LIABILITY.
							</p>
							<p>
									h. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
									EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. AS A RESULT, THE ABOVE
									LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU IN WHOLE OR IN PART, AND THE
									FOREGOING SECTIONS 8(c), 8(d), AND 8(e) WILL NOT APPLY TO A RESIDENT OF NEW
									JERSEY, TO THE EXTENT DAMAGES TO SUCH NEW JERSEY RESIDENT ARE THE RESULT OF
									YOLOMEAL’S NEGLIGENT, FRAUDULENT, RECKLESS, OR INTENTIONAL MISCONDUCT.
							</p>
							<p>
									8. <strong>Indemnification</strong>
									<br/>
									<br/>
									You agree to release, indemnify, and defend Yolomeal Entities from all
									third-party claims and costs (including reasonable attorneys’ fees) arising
									out of or related to: i) your use of the Yolomeal Platform, ii) Your
									Content, iii) your conduct or interactions with other users of the Yolomeal
									Platform, or iv) your breach of any part of this Agreement. We will
									promptly notify you of any such claim and will provide you (at your
									expense) with reasonable assistance in defending the claim. You will allow
									us to participate in the defense and will not settle any such claim without
									our prior written consent. We reserve the right, at our own expense, to
									assume the exclusive defense of any matter otherwise subject to
									indemnification by you. In that event, you will have no further obligation
									to defend us in that matter.
							</p>
							<p>
									9.
									<strong>
											Legal Disputes and Arbitration Agreement for users in the United States
											and Canada
											<br/>
											<br/>
									</strong>
									Please Read the Following Clause Carefully – It May Significantly Affect
									Your Legal Rights, Including Your Right to File a Lawsuit in Court
							</p>
							<p>
									a. <strong>Initial Dispute Resolution</strong>. We are available by email
									at <a href="mailto:info@yolomeal.com"><u>info@yolomeal.com</u></a> to address any
									concerns you may have regarding your use of the Yolomeal Platform. Most
									concerns may be quickly resolved in this manner. The parties agree to use
									best efforts to settle any dispute, claim, question, or disagreement
									directly through consultation and good faith negotiations with us, before
									initiating a lawsuit or arbitration.
							</p>
							<p>
									b. <strong>Agreement to Binding Arbitration</strong>. If an agreed upon
									solution is not reached through informal dispute resolution as outlined
									above within a period of thirty (30) days pursuant to the initial dispute
									resolution procedure provided in Section 10(a) above, then either party may
									initiate binding arbitration to formally resolve claims, subject to the
									terms and exclusion set forth below. All claims arising out of or relating
									to these Terms of Service (including their formation, performance, and
									breach), the parties’ relationship with each other, and/or your use of the
									Yolomeal Platform will be finally settled by binding arbitration
									administered by AAA, in accordance with the AAA Consumer Arbitration Rules,
									excluding any rules or procedures governing or permitting class actions.
									Each party will have the right to use legal counsel in connection with
									arbitration at its own expense. The parties will select a single neutral
									arbitrator in accordance with the AAA Consumer Arbitration Rules. The
									arbitrator, and not any federal, state or local court or agency, will have
									exclusive authority to resolve all disputes arising out of or relating to
									the interpretation, applicability, enforceability, or formation of these
									Terms of Service, including, but not limited to, any claim that all or any
									part of these Terms of Service is void or voidable. Either party may elect
									to appear at the arbitration by phone or, if mutually agreed, to conduct it
									online, in lieu of appearing live. The arbitrator will be empowered to
									grant whatever relief would be available in a court under law or in equity.
									The arbitrator’s award will be in writing and provide a statement of the
									essential findings and conclusions, will be binding on the parties and may
									be entered as a judgment in any court of competent jurisdiction. The
									interpretation and enforcement of these Terms of Service will be subject to
									the Federal Arbitration Act.
							</p>
							<p>
									c. <strong>Rules</strong>. The AAA rules governing the arbitration may be
									accessed at <u>https://www.adr.org/</u>. Each party will be responsible for
									its arbitration fees as set forth in the AAA Consumer Arbitration Rules.
									You will not be required to pay fees and costs incurred by us if you do not
									prevail in arbitration. The parties understand that, absent this mandatory
									provision, they would have the right to sue in court and have a jury trial.
									They further understand that the right to discovery may be more limited in
									arbitration than in court.
							</p>
							<p>
									d. <strong>Class Action and Class Arbitration Waiver</strong>. The parties
									each further agree that any arbitration will be conducted in our respective
									individual capacities only and not as a class action or other
									representative action, and each expressly waives its respective right to
									file a class action or seek relief on a class basis. If any court or
									arbitrator determines that the class action waiver set forth in this
									paragraph is void or unenforceable for any reason or that an arbitration
									can proceed on a class basis, then the arbitration provision set forth
									above in Section 10(b) will be deemed null and void in its entirety and the
									parties will be deemed to have not agreed to arbitrate disputes.
							</p>
							<p>
									e. <strong>Exception: Small Claims Court Claims</strong>. Notwithstanding
									the parties’ agreement to resolve all disputes through arbitration, either
									party may seek relief in a small claims court for disputes or claims within
									the scope of that court’s jurisdiction and on an individual (non-class)
									basis only.
							</p>
							<p>
									f. <strong>Exception: Intellectual Property and Content Claims</strong>.
									Notwithstanding the parties’ agreement to resolve all disputes through
									arbitration, this binding arbitration section does not apply to disputes
									relating to either party’s intellectual property rights (trademark,
									copyright, patent, and trade secret) or violations of Yolomeal’s Acceptable
									Use Policy. For further clarification, disputes relating to your privacy
									rights are not excepted from this binding arbitration section.
							</p>
							<p>
									g. <strong>30 Day Right to Opt-Out</strong>. You have the right to opt-out
									and not be bound by the arbitration and class action waiver provisions set
									forth in Sections 10(b) and 10(c) by sending written notice of your
									decision to opt-out by emailing us at <a href="mailto:info@yolomeal.com">info@yolomeal.com</a><u>. </u>The notice
									must be sent within thirty (30) days of registering to use the Yolomeal
									Platform, otherwise you will be bound to arbitrate disputes in accordance
									with the terms of those sections. If you opt out of these arbitration
									provisions, we also will not be bound by them.
							</p>
							<p>
									h. <strong>Exclusive Venue for Litigation</strong>. To the extent that the
									arbitration provisions set forth in Section 10(b) do not apply, or if you
									have opted out of arbitration, the parties agree that any litigation
									between them must be filed exclusively in state or federal courts located
									in Santa Clara County, California (except for small claims court actions
									which may be brought in the county where you reside). The parties expressly
									consent to exclusive jurisdiction in Santa Clara County, California for any
									litigation other than small claims court actions. In the event of
									litigation relating to these Terms of Service, the parties agree to waive,
									to the maximum extent permitted by law, any right to a jury trial, except
									where a jury trial waiver is not permissible under applicable law.
							</p>
							<p>
									10. <strong>General Terms</strong>
							</p>
							<p>
									a. <strong>Changes to these Terms of Service</strong>. We may amend this
							Agreement (including any policies, such as the<em><u>Privacy Policy</u></em>, <em><u>Acceptable Use Policy</u></em>,    <em><u>Copyright Policy</u></em>, and <em><u>Trademark Policy</u></em> that
									are incorporated into this Agreement) at any time, in our sole discretion.
									If we amend material terms to this Agreement, such amendment will be
									effective after we send you notice of the amended agreement. Such notice
									will be in our sole discretion, and the manner of notification could
									include, for example, via email, posted notice on the Yolomeal Platform, or
									other manner. Your failure to cancel your account, or cease use of the
									Yolomeal Platform, after receiving notification of the amendment, will
									constitute your acceptance of the amended terms. If you do not agree to the
									amendments or to any of the terms in this Agreement, your only remedy is to
									cancel your account or to cease use of the Yolomeal Platform.
							</p>
							<p>
									b. <strong>Use Outside of the United States</strong>. Yolomeal expressly
									disclaims any representation or warranty that the Yolomeal Platform
									complies with all applicable laws and regulations outside of the United
									States. If you use the Yolomeal Platform outside of the United States, you
									expressly understand and agree that you are responsible for determining
									compliance with different laws, regulations, or customs that may apply in
									connection with your use of the Yolomeal Platform.
							</p>
							<p>
									c. <strong>Export</strong>. The Yolomeal Platform is controlled and
									operated from our United States offices in California. Yolomeal software is
									subject to United States export controls. No software for Yolomeal may be
									downloaded or otherwise exported or re-exported in violation of any
									applicable laws or regulations. You represent that you are not (1) located
									in a country that is subject to a U.S. government embargo, or that has been
									designated by the U.S. government as a “terrorist supporting” country, and
									(2) listed on any U.S. government list of prohibited or restricted parties.
							</p>
							<p>
									d. <strong>Applications and Mobile Devices</strong>. If you access the
									Yolomeal Platform through a Yolomeal application, you acknowledge that this
									Agreement is between you and Yolomeal only, and not with another
									application service provider or application platform provider (such as
									Apple Inc. or Google Inc.), which may provide you the application subject
									to its own terms. To the extent you access the Yolomeal Platform through a
									mobile device, your wireless carrier’s standard charges, data rates, and
									other fees may apply.
							</p>
							<p>
									e. <strong>Survival</strong>. The following provisions will survive
									expiration or termination of this Agreement: Section 2(e)(Termination),
									2(g)(Feedback), Section 3(Your Content), Section 4(a)-(b) and (d)-(f)(Our
									Content and Materials), Section 8 (Disclaimers and Limitation of
									Liability), Section 9 (Indemnification), Sections 10 (Legal Disputes and
									Arbitration Agreement), and Section 11 (General Terms).
							</p>
							<p>
									f. <strong>Notice for California Users</strong>. Under California Civil
									Code Section 1789.3, California web users are entitled to the following
									specific consumer rights notice: The Yolomeal Platform is provided by
									Yolomeal, Inc., St. Paul, MN. If you have a question or complaint regarding
									the Yolomeal Platform, please contact Yolomeal at <u><a href="mailto:info@yolomeal.com">info@yolomeal.com</a></u>.
									California residents may reach the Department of Consumer Affairs Consumer
									Information Division at 1625 N. Market Blvd., Suite S-202, Sacramento,
									California 95834, or by telephone at (916) 445-1254 or (800) 952-5210 or
									Hearing Impaired at TDD (800) 326-2297 or TDD (916) 322-1700.
							</p>
							<p>
									g. <strong>Government End Users</strong>. Any Yolomeal software and related
									documentation are “Commercial Items,” as that term is deﬁned at 48 C.F.R.
									§2.101, consisting of “Commercial Computer Software” and “Commercial
									Computer Software Documentation,” as such terms are used in 48 C.F.R.
									§12.212 or 48 C.F.R. §227.7202 (as applicable). Consistent with 48 C.F.R.
									§12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4 (as applicable), the
									Commercial Computer Software and Commercial Computer Software Documentation
									are being licensed to U.S. government end users: (i) only as Commercial
									Items; and (ii) with only those rights as are granted to all other end
									users pursuant to this Agreement.
							</p>
							<p>
									h. <strong>Assignment</strong>. You may not assign or transfer this
									Agreement (or any of your rights or obligations under this Agreement)
									without our prior written consent; any attempted assignment or transfer
									without complying with the foregoing will be void. We may freely assign or
									transfer this Agreement. This Agreement inures to the benefit of and is
									binding upon the parties and their respective legal representatives,
									successors, and assigns.
							</p>
							<p>
									i. <strong>Electronic Communications</strong>. You consent to receive
									communications from us by email in accordance with this Agreement and
									applicable law. You acknowledge and agree that all agreements, notices,
									disclosures and other communications that we provide to you electronically
									will satisfy any legal requirement that such communications be in writing.
							</p>
							<p>
									j. <strong>Entire Agreement/ Severability</strong>. This Agreement
									supersedes all prior terms, agreements, discussions and writings regarding
									the Yolomeal Platform and constitutes the entire agreement between you and
									us regarding the Yolomeal Platform (except as to services that require
									separate written agreement with us, in addition to this Agreement). If any
									provision in this Agreement is found to be unenforceable, then that
									provision will not affect the enforceability of the remaining provisions of
									the Agreement, which will remain in full force and effect.
							</p>
							<p>
									k. <strong>Interpretation</strong>. In construing or interpreting the terms
									of this Agreement: (i) the headings in this Agreement are for convenience
									only, and are not to be considered, and (ii) no presumption is to operate
									in either party’s favor as a result of its counsel’s role in drafting this
									Agreement.
							</p>
							<p>
									l. <strong>Notices</strong>. All notices permitted or required under this
									Agreement, unless specified otherwise in this Agreement, must be sent in
									writing as follows in order to be valid: (i) if to you, by us via email to
									the address associated with your account, and (ii) if to us, by you via
									<a href="mailto:legal@yolomeal.com">legal@yolomeal.com</a>. Notices will be deemed given (a) if to you, when
									emailed, and (b) if to us, on receipt by us.
							</p>
							<p>
									m. <strong>Relationship</strong>. This Agreement does not create a joint
									venture, agency, partnership, or other form of joint enterprise between you
									and us. Except as expressly provided herein, neither party has the right,
									power, or authority to create any obligation or duty, express or implied,
									on behalf of the other.
							</p>
							<p>
									n. <strong>Waiver</strong>. No waiver of any terms will be deemed a further
									or continuing waiver of such term or any other term. Our failure to assert
									a right or provision under this Agreement will not constitute a waiver of
									such right or provision.
							</p>
							<p>
									o. <strong>Further Assurance</strong>s. You agree to execute a hard copy of
									this Agreement and any other documents, and to take any actions at our
									expense that we may request to confirm and effect the intent of this
									Agreement and any of your rights or obligations under this Agreement.
							</p>
							<p>
							p. <strong>Contact</strong>. Feel free to contact us through    <u><a href="mailto:info@yolomeal.com">info@yolomeal.com</a></u> with any questions about these terms. Yolomeal is
									a Delaware corporation.
							</p>
						</div>
					</div>
				</section> 
			</div>
		</main>
	);
}

export default TermsPage;