import React from 'react';
import { Link } from "react-router-dom";
import MenuBar from '../components/Menubar';

type Props = { 
	user: any
};

const NotificationsPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg faq-bg">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="notification-lists"> 
						<div className="yolo-container"> 
							<ul> 
								<li> <a href="link1"> Someone has Upvoted your French Fries YOLO!</a> </li>
								<li> <a href="link2"> Someone just added  new YOLO in your area! </a> </li>
								<li> <a href="link3"> Jim Anderson you are following just added a YOLO or Upvote! </a> </li>
							</ul>
						</div>
						<div className="mt-4 mb-4 d-flex black justify-content-center align-items-center uppercase">
							<h1><Link to="/my-page">BACK</Link></h1> 
						</div>
					</div> 
				</section> 
			</div>
		</main>
	);
}

export default NotificationsPage;
