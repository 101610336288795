export const tagArray = [
  {id: 1, category: 1, name: 'BBQ'},
  {id: 2, category: 1, name: 'African'},
  {id: 3, category: 1, name: 'Beef'},
  {id: 4, category: 1, name: 'Chinese'},
  {id: 5, category: 1, name: 'Chicken'},
  {id: 6, category: 1, name: 'French'},
  {id: 7, category: 1, name: 'Fish'},
  {id: 8, category: 1, name: 'Indian'},
  {id: 9, category: 1, name: 'Gluten free'},
  {id: 10, category: 1, name: 'Italian'},
  {id: 11, category: 1, name: 'Lamb'},
  {id: 12, category: 1, name: 'Japanese'},
  {id: 13, category: 3, name: 'Deep Dish'},
  {id: 14, category: 3, name: 'Detroit Style'},
  {id: 15, category: 3, name: 'Margherita'},
  {id: 16, category: 3, name: 'Gluten Free'},
  {id: 17, category: 3, name: 'Vegan'},
  {id: 18, category: 7, name: 'Eggs'},
  {id: 19, category: 7, name: 'Pancakes'},
  {id: 20, category: 7, name: 'Meat'},
  {id: 21, category: 7, name: 'Bread'},
  {id: 22, category: 7, name: 'Gluten Free'},
  {id: 23, category: 7, name: 'Vegan'},
  {id: 24, category: 6, name: 'Cakes'},
  {id: 25, category: 6, name: 'Pies'},
  {id: 26, category: 6, name: 'Pastries'},
  {id: 27, category: 6, name: 'Creamy Things'},
  {id: 28, category: 6, name: 'Gluten Free'},
  {id: 29, category: 6, name: 'Vegan'},
  {id: 30, category: 2, name: 'Cook Medium Rare'},
  {id: 31, category: 2, name: 'Plant Based'},
  {id: 32, category: 2, name: 'Gluten Free'},
  {id: 33, category: 2, name: 'Vegan'},
  {id: 34, category: 4, name: 'Meat'},
  {id: 35, category: 4, name: 'Seafood'},
  {id: 36, category: 4, name: 'Vegetable'},
  {id: 37, category: 4, name: 'Gluten Free'},
  {id: 38, category: 4, name: 'Vegan'},
  {id: 39, category: 5, name: 'Soup'},
  {id: 40, category: 5, name: 'Vegetable'},
  {id: 41, category: 5, name: 'Salad'},
  {id: 42, category: 5, name: 'Gluten Free'},
  {id: 43, category: 5, name: 'Vegan'},
  {id: 44, category: 13, name: 'Meat'},
  {id: 45, category: 13, name: 'Vegetable'},
  {id: 47, category: 13, name: 'BBQ'},
  {id: 48, category: 13, name: 'Gluten Free'},
  {id: 49, category: 13, name: 'Vegan'},
  {id: 50, category: 1, name: 'Pasta'},
  {id: 51, category: 1, name: 'korean'},
  {id: 52, category: 1, name: 'Pork'},
  {id: 53, category: 1, name: 'Mexican'},
  {id: 54, category: 1, name: 'Shellfish'},
  {id: 55, category: 1, name: 'portuguese'},
  {id: 56, category: 1, name: 'veal'},
  {id: 57, category: 1, name: 'Russian'},
  {id: 58, category: 1, name: 'vegetarian'},
  {id: 59, category: 1, name: 'south american'},
  {id: 60, category: 1, name: 'vegan'},
  {id: 61, category: 1, name: 'spanish'},
  {id: 62, category: 1, name: 'Thai'},
  {id: 63, category: 1, name: 'Vietnamese'},
  {id: 66, category: 13, name: 'AREPA'},
  {id: 67, category: 13, name: 'BURRITO'},
  {id: 68, category: 13, name: 'EMPENADA'},
  {id: 69, category: 13, name: 'GRILLED'},
  {id: 70, category: 13, name: 'HAND PIE'},
  {id: 71, category: 13, name: 'ON-A-STICK'},
  {id: 72, category: 13, name: 'PANINI'},
  {id: 73, category: 13, name: 'PAPUSA'},
  {id: 74, category: 13, name: 'PRETZEL'},
  {id: 75, category: 13, name: 'SANDWICH'},
  {id: 76, category: 13, name: 'SUB/HOAGIE'},
  {id: 77, category: 13, name: 'TACO'},
  {id: 78, category: 13, name: 'TOAST'},
  {id: 79, category: 13, name: 'WRAPS'},
  {id: 80, category: 6, name: 'Doughnut'}
];

export const categoriesArray = [
  { id: 1, name: 'ENTREES' },
  { id: 2, name: 'BURGERS' },
  { id: 3, name: 'PIZZA' },
  { id: 4, name: 'APPETIZERS' },
  { id: 5, name: 'SIDES' },
  { id: 6, name: 'DESSERT' },
  { id: 7, name: 'BREAKFAST' },
  { id: 13, name: 'HANDHELD' },
  { id: 8, name: 'WINE' },
  { id: 9, name: 'MIXED DRINKS' },
  { id: 10, name: 'BEER' },
  { id: 11, name: 'COFFEE' },
  { id: 12, name: 'OTHER DRINKS' }
];