import React from 'react';
import MenuBar from '../components/Menubar';
import { Link } from 'react-router-dom';

type Props = { 
	user: any
};

const FaqPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="about-content terms-content faq-content mb-4 pb-4 f-15">
						<div className="terms-box bg-white">
							<h3 className="text-center lulo-bold about-title">HOW IT WORKS</h3>
							<section >
								<p>
									Don't you hate spending 15 minutes deciding what to order and then dropping $$$ on something that ends
									up being mediocre? Yeah, us too. That's why we created Yolomeal.com.
								</p>
								<p>
									Millions of people eat restaurant food every day. In our search for great food, we will never even
									taste a fraction of that food, so why
									not put their tongues to work for us?
								</p>
								<p>
									Yolomeal is a completely positive experience that crowdsources
									each city's tastiest menu items then mixes in some magic to reveal the best food in your city, or at
									any
									restaurant you might visit. No more wasted money or time; just delicious food. 😉
								</p>
							</section>
							<section className="heading-points">
								<h3>How to use Yolomeal to find new, delicious dishes:</h3>
								<ul>
									<li>
										<b>1. </b>
										Select your city of interest.
									</li>
									<li>
										<b>2. </b>
										Click the <Link style={{color: 'red', fontSize: '18px'}} to="/eat">"Eat"</Link> button to see our 8 food categories. Click on your category of interest. You'll
										see a ranked list of your city's best menu items in that category.
									</li>
									<li>
										<b>3. </b>
										Refine the list of foods within any of the categories by clicking the filter button. This will
										help you narrow down the categories to pasta, vegan, etc!
									</li>
									<li>
										<b>4. </b>
										You can also click "Restaurant Search" from the main menu to see if a restaurant you plan to
										visit has anything Yolo worthy.
									</li>
								</ul>
							</section>
							<section className="heading-points">
								<h3>How to add your favorite menu items to Yolomeal:</h3>
								<ul>
									<li>
										<b>1. </b>
										If you love a menu item not yet on the list, just click <Link style={{color: 'red', fontSize: '18px'}} to="/add-yolo">"Add Your Favorites"</Link> to start saving your
										own.
									</li>
								</ul>
							</section>
							<p>
								While adding faves, be sure to add all the appropriate tags for that item, so it's easier for other
								people
								to find awesome food!
							</p>
							<section className="heading-points">
								<h3>How to tell others that you LOVE a menu item in Yolomeal:</h3>
								<ul>
									<li>
										<b>1. </b>
										Click "Tap to Upvote" on that item to help it move up in the rankings.
									</li>
									<li>
										<b>2. </b>
										All the favorites you save and your upvotes will be collected on your "My Page." This list can be
										easily shared with your friends.
									</li>
								</ul>
							</section>
							<p>
								Thanks for using Yolomeal and making your city a more delicious place!
							</p>
						</div>
					</div>
				</section>
			</div>
		</main>
	);
}

export default FaqPage;
