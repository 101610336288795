import React from 'react';
import { Link } from "react-router-dom";
import MenuBar from '../components/Menubar';

type Props = { 
	user: any
};

const ChangeIdentityPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg faq-bg">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="  change-id text-center"> 
						<div className="yolo-container text-center"> 
							<h3 className="futuralt-book f-24 mt-4 pt-4 mb-2 text-center"> You are allowed 1 name change per year. Choose wisely :)</h3>
							<input value={props.user.name} /> 
						</div>
						<div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
							<h1><Link to="/my-page">BACK</Link></h1> 
							<h1>Edit</h1> 
							<h1>Save</h1>
						</div>
					</div> 
				</section> 
			</div>
		</main>
	);
}

export default ChangeIdentityPage;
