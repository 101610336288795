import React, { useContext, useState } from 'react';
import User from '../components/Utils';
import LoginPage from './LoginPage';
import MenuBar from '../components/Menubar';
import LoaderContext from '../components/LoaderContext';

type Props = {
  user: User,
  handleLogin(user): void
};

const AccountSettingPage: React.FC<Props> = (props) => {

  const [newName, setNewName] = useState('');
  const [verifyNewName, setVerifyNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [verifyNewEmail, setVerifyNewEmail] = useState('');
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const onLoginBackClick = async () => {
  }

  const saveChangeEmail = async ()=>{
    const stored = localStorage.getItem('YoloMeal');
    let userData: User;
    if(stored) {
      userData = JSON.parse(stored);
    }
    if(newEmail === verifyNewEmail){
      showLoader();
      fetch(
        process.env.REACT_APP_SERVER_URL +
        '/user/email?email=' +
        newEmail,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + props.user.token,
          })
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            userData.email = result.result.email;
            props.user.email = result.result.email;
            console.log(userData);
            localStorage.setItem('YoloMeal', JSON.stringify(userData));
            hideLoader();
          },
          (error) => {
            hideLoader();
            console.log(error)
          }
        );
    }
  }

  const saveChangeName = async () =>{
    const stored = localStorage.getItem('YoloMeal');
    let userData: User;
    if(stored) {
      userData = JSON.parse(stored);
    }
    if(newName === verifyNewName){
      var seperatedName = newName.split(' ');
      console.log(seperatedName);
      showLoader();
      var url = process.env.REACT_APP_SERVER_URL + '/user/name?firstname=' +
        seperatedName[0] + "&lastname=";
      if(seperatedName.length > 1){
        url = url + seperatedName[1];
      }
      console.log(url)
      fetch(
        url,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + props.user.token,
          })
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            userData.name = `${result.result.firstName} ${result.result.lastName}`;
            props.user.name = `${result.result.firstName} ${result.result.lastName}`;
            localStorage.setItem('YoloMeal', JSON.stringify(userData));
            hideLoader();
          },
          (error) => {
            hideLoader();
            console.log(error)
          }
        );
    }
  }

  const isNameButtonDisabled = () => {
    return newName !== verifyNewName || newName == "";
  }
  const isEmailButtonDisables = () =>{
    return newEmail !== verifyNewEmail || newEmail == "";
  }

  if (!props.user.isLogged) {
    return (
      <LoginPage user={props.user} handleLogin={props.handleLogin} showBack={false} showHome={true} backgroundImage={true} handleBack={onLoginBackClick} />
    )
  } else {
    return (
      <main className="container">
        <MenuBar user={props.user} />
        <section >
          <div className="d-flex align-items-center justify-content-center flex-col">
            <div className="d-flex flex-col w-50">
              <h2 className="accountSettingH1">Current Name:{props.user.name}</h2>
              <div className="d-flex flex-col">
              <input
                type="text"
                className="field xl-filed mb-2"
                placeholder="Enter New Name"
                onChange={(e) => setNewName(e.target.value)}
              />
                <input
                  type="text"
                  className="field xl-filed mb-2"
                  placeholder="Verify New Name Again"
                  onChange={(e) => setVerifyNewName(e.target.value)}
                />
              </div>
            </div>
            <button
              className="account-setting-btn"
              disabled={isNameButtonDisabled()}
              onClick={() => saveChangeName()}
            >
              Change Name
            </button>
            <div className="d-flex flex-col w-50">
              <h2 className="accountSettingH1">Current Email:{props.user.email}</h2>
              <div className="d-flex flex-col">
                <input
                  type="text"
                  className="field xl-filed mb-2"
                  placeholder="Enter New Email"
                  onChange={(e) => setNewEmail(e.target.value)}/>
                <input
                  type="text"
                  className="field xl-filed mb-2"
                  placeholder="Verify New Email Again"
                  onChange={(e) => setVerifyNewEmail(e.target.value)}/>
              </div>
            </div>
            <button
              className="account-setting-btn"
              disabled={isEmailButtonDisables()}
              onClick={() => saveChangeEmail()}
            >
              Change Email
            </button>
          </div>
        </section>
      </main>
    )
  }
}

export default AccountSettingPage;