import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";

import MenuBar from './../components/Menubar';
import User from './../components/Utils';
import CategoryItems from '../components/CategoryItems';
import LoaderContext from './../components/LoaderContext';
import LoginPage from './LoginPage';
import { categoriesArray } from '../utils/helpers';

type Props = {
	user: User,
	handleLogin(user): void
};

const loadData = () => {

}

const UserPage: React.FC<Props> = (props) => {
	const [error, setError] = useState('');
	const [userName, setUserName] = useState("");
	const [items, setItems] = useState<any[]>([]);
	const [showLogin, setShowLogin] = useState(false);
	const { id }: any = useParams();
	const { showLoader, hideLoader } = useContext(LoaderContext);
	let didCancel = false;

	const log = async (message: any) => {
		if (process.env.REACT_APP_LOG === 'true') {
			console.log(message);
		}
	}

	const handleErrors = (response) => {
		log(response);
		/*
		ok: false
		​redirected: false
		​status: 403
		​statusText: ""
		​type: "cors"
		*/
		if (!response.ok) {
			var err = response.statusText;
			if (err === '') {
				err = 'Error';
			}
			throw Error(err);
		}
		return response;
	}

	const loadData = async () => {
		showLoader();
		var headers = new Headers({
				'Content-Type': 'application/json',
				"Accept":"application/json",
			}
		);
		if (props.user.isLogged) {
			headers.append('Authorization', 'Bearer ' + props.user.token);
		}
		fetch(process.env.REACT_APP_SERVER_URL + "/users/" + id + "?v=" + Date.now(), {
			method: 'GET',
			headers: headers
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(
				(result) => {
					log(result);
					if (!didCancel) {
						hideLoader();
						setUserName(result.userName);
						setItems(result.items);
						setError("");
					}
				},
				(error) => {
					log(error);
					if (!didCancel) {
						hideLoader();
						setError(error.message);
					}
				}
			)
	};

	useEffect(() => {
		loadData();

		return () => {
			didCancel = true;
		};
	}, [props.user.isLogged]);

	const handleUpvote = async (id) => {
		showLoader();
		const data = { userId: props.user.id};
		fetch(process.env.REACT_APP_SERVER_URL + "/items/" + id + "/upvote", {
			method: 'POST',
			headers:  new Headers({
				'Content-Type': 'application/json',
				"Accept":"application/json",
				'Authorization': 'Bearer ' + props.user.token
			}),
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(
				(result) => {
					log(result);
					setItems(items.map(item => item.id === id ? {...item, upvoted: true, upvoteCount: item.upvoteCount + 1} :  item ));
					setError("");
					hideLoader();
				},
				(error) => {
					log(error);
					setError(error.message);
					hideLoader();
				}
			)
	}

	const handleLogin = async () => {
		setShowLogin(true);
	}

	const onLoginBackClick = async () => {
		setShowLogin(false);
	}

	const getCategoryItems = (category) => {
		let list: any[] = [];
		for (var i = 0; i < items.length; i++) {
			if (items[i].categoryId === category) {
				list.push(items[i]);
			}
		}
		return list;
	}

	if (showLogin && !props.user.isLogged) {
		return (
			<LoginPage user={props.user} handleLogin={props.handleLogin} showBack={true} showHome={false} backgroundImage={true} handleBack={onLoginBackClick}/>
		)
	} else {
		return (
			<main>
				{userName !== '' &&
					<div className="container mypage">
						<MenuBar user={props.user}/>
						<section>
							<div className="mypage-title">
								<h1 className="text-center trend-sans"> {userName} PAGE</h1>
							</div>
							<div className="page-box-main">
								{error !== '' &&
									<p>{error}</p>
								}
								<div>
									{categoriesArray.map(category => (
										<CategoryItems
											key={category.id}
											items={getCategoryItems(category.id)}
											name={category.name}
											user={props.user}
											editable={false}
											handleLogin={handleLogin}
											handleUpvote={handleUpvote}
											loadData={loadData}
										/>
									))}
								</div>
							</div>
						</section>
					</div>
				}
			</main>
		)
	}
}

export default UserPage;