import React from 'react';
import Modal from 'react-modal';

type Props = {
  isOpen: boolean;
  handleModalClose(): void;
  handleTagsConfirmYes(): void;
  handleTagsConfirmNo(): void
};

const confirmBoxStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '30%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  }
};

const TagsConfirmationModal: React.FC<Props> = (props) => {

  const closeModel = ()=>{
    props.handleModalClose();
  }

  const handleTagsConfirmYes = () => {
    props.handleTagsConfirmYes();
  }

  const handleTagsConfirmNo = () => {
    props.handleTagsConfirmNo();
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={closeModel} contentLabel="Confirmation"
           style={confirmBoxStyle}>
      <div className="modal small-container add-yolo-info text-center notification-settings">
        <div className="modal-content">
          <p className='futuralt-book mt-4 pt-2'>Are you sure you want to save?</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button className="next-button" onClick={handleTagsConfirmYes}>Yes</button>
            <button className="next-button" onClick={handleTagsConfirmNo}>No</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default TagsConfirmationModal;