import React, { useState } from "react";

type Props = { 
	path: string,
	page: string
};

const CopyUrl: React.FC<Props> = (props) => {
	const [isClicked, setIsClicked] = useState(false);

	const copyUrl = async () => {
		//var path = window.location.href;
		setIsClicked(true);
		if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
			copyToClipboardIos(props.path)
		} else {
			copyToClipboard(props.path);
		}		
	}

	const copyToClipboard = (text: string) => {
		var textArea = document.createElement("textarea");
	
		//
		// *** This styling is an extra step which is likely not required. ***
		//
		// Why is it here? To ensure:
		// 1. the element is able to have focus and selection.
		// 2. if element was to flash render it has minimal visual impact.
		// 3. less flakyness with selection and copying which **might** occur if
		//    the textarea element is not visible.
		//
		// The likelihood is the element won't even render, not even a flash,
		// so some of these are just precautions. However in IE the element
		// is visible whilst the popup box asking the user for permission for
		// the web page to copy to the clipboard.
		//
	
		// Place in top-left corner of screen regardless of scroll position.
		textArea.style.position = 'fixed';
		textArea.style.top = "0";
		textArea.style.left = "0";
	
		// Ensure it has a small width and height. Setting to 1px / 1em
		// doesn't work as this gives a negative w/h on some browsers.
		textArea.style.width = '2em';
		textArea.style.height = '2em';
	
		// We don't need padding, reducing the size if it does flash render.
		textArea.style.padding = "0";
	
		// Clean up any borders.
		textArea.style.border = 'none';
		textArea.style.outline = 'none';
		textArea.style.boxShadow = 'none';
	
		// Avoid flash of white box if rendered for any reason.
		textArea.style.background = 'transparent';
	
		textArea.value = text;
	
		document.body.appendChild(textArea);
		textArea.select();
		try {
			var successful = document.execCommand('copy');
			//var msg = successful ? 'successful' : 'unsuccessful';
			//console.log('Copying text command was ' + msg);
		} catch (err) {
			//console.log('Oops, unable to copy');
		}
		document.body.removeChild(textArea);
	}

	const copyToClipboardIos = (str: string) => {
		var el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.setAttribute('style', "position: absolute, left: -9999px");
		document.body.appendChild(el);
	
		// save current contentEditable/readOnly status
		var editable = el.contentEditable;
		var readOnly = el.readOnly;
	
		// convert to editable with readonly to stop iOS keyboard opening
		el.contentEditable = "true";
		el.readOnly = true;
	
		// create a selectable range
		var range = document.createRange();
		range.selectNodeContents(el);
	
		// select the range
		var selection = window.getSelection();
		if (selection) {
			selection.removeAllRanges();
			selection.addRange(range);
		}
		el.setSelectionRange(0, 999999);
	
		// restore contentEditable/readOnly to original state
		el.contentEditable = editable;
		el.readOnly = readOnly;
	
		document.execCommand('copy');
		document.body.removeChild(el);
	}


	if (props.page === "mypage") {
		var message = "SHARE YOUR LIST! CLICK TO COPY URL"
		if (isClicked) {
			message = "COPIED!"
		}
			return (
			<h2 className="f-38 pointer text-center" onClick={() => copyUrl() }>{message}</h2>
		)
	} else {
		var message = "Click here to copy your referral link"
		if (isClicked) {
			message = "Copied!"
		}
		return (
			<p><b><span className="pointer" onClick={() => copyUrl() }>{message}</span></b></p>
		)
	}
}

export default CopyUrl;
