import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import MenuImage from '../images/menu-icon.png';

type Props = {
  user: any;
};

const MenuBar: React.FC<Props> = (props) => {
  const [active, setActive] = useState<Boolean>(true);

  const menuToggle = async () => {
    setActive(!active);
  };

  useEffect(() => {
    if (window.innerWidth < 767) {
      setActive(false);
    }
  }, []);

  return (
    <header className="header">
      <ul
        className={
          active
            ? 'd-flex align-items-center white uppercase futuralt-book'
            : 'd-flex align-items-center white uppercase futuralt-book hidden'
        }
      >
        <li>
          <NavLink to="/home">Home</NavLink>
        </li>
        <li>
          <NavLink to="/my-page">My Page</NavLink>
        </li>
        <li>
          <NavLink to="/faq">HOW IT WORKS</NavLink>
        </li>
        <li>
          <NavLink to="/search-restaurant">Restaurant Search</NavLink>
        </li>
        {/*<li>*/}
        {/*  <NavLink to="/referral">Referral Program</NavLink>*/}
        {/*</li>*/}
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        {props.user.isLogged ? (
          <li>
            <NavLink to="/logout">Logout</NavLink>
          </li>
        ) : (
          <li>
            <NavLink to="/login">Login</NavLink>
          </li>
        )}
      </ul>
      <div className="menu-toggle" onClick={() => menuToggle()}>
        <img src={MenuImage} alt="Toggle" />
      </div>
    </header>
  );
};

export default MenuBar;
