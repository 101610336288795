import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import AmazonImage from '../images/amazon-icon.png';
import AppleSignin from 'react-apple-signin-auth';
import AppleIcon from '../images/AppleLogo.svg';
import MenuBar from '../components/Menubar';

type Props = {
  user: any;
  showHome: boolean;
  showBack: boolean;
  handleLogin(user: any): void;
  handleBack(): void;
  backgroundImage: boolean
};

const defaultProps: Props = {
  user: null,
  showHome: true,
  showBack: false,
  handleLogin: () => {},
  handleBack: () => {},
  backgroundImage: true
};

const LoginPage: React.FC<Props> = (props) => {
  const [error, setError] = useState("");
  const [appleInfo, setappleInfo] = useState();
  const [fbInfo, setfbInfo] = useState();
  const [appleSignIn, setappleSignIn] = useState(true);
  const [showFbSignIn, setFbSignIn] = useState(true);
  const loginButtonClass = 'd-flex justify-content-space-between facebook-button';
  const appId: string = process.env.REACT_APP_FACEBOOK_APP_ID || '';
  const appleRedirectURL: string = process.env.REACT_APP_APPLE_REDIRECT_URL || 'https://3c46-119-155-28-245.ngrok-free.app';
  const appleClientId: string = process.env.REACT_APP_APPLE_CLIENT_ID || 'com.yolo.meal';
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [inputText, setInputText] = useState(""); // Current value of the input
  const [placeholder, setPlaceholder] = useState("Add your real name here....."); // Placeholder text
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  const authOptions = {
    clientId: appleClientId,
    scope: 'name email',
    redirectURI: appleRedirectURL,
    state: 'state',
    nonce: 'nonce',
    usePopup: true,
  }
  useEffect(() => {
    let loginInfoStr = localStorage.getItem("loginInfo");
    if (loginInfoStr) {
      let loginInfo = JSON.parse(loginInfoStr);
      console.log(loginInfo);
      setappleInfo(loginInfo.facebookLogin);
      setappleSignIn(false);
      setFbSignIn(false);
      setfbInfo(loginInfo.appleLogin);
      
    }
  }, [])

  const renderAppleAuthButton = (props: JSX.IntrinsicAttributes & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button {...props}>
      <img
        src={AppleIcon}
        alt="Apple Icon"
        style={{ marginRight: '5px', width: '24px', height: '24px', alignItems: 'center' }}
      />
      Sign in with Apple
    </button>
  );

  const handleFacebookClick = async () => {
    //showLoader();
  };

  const getReferrer = () => {
    var path = window.location.href;
    const arr = path.split('?');
    if (arr.length === 2) {
      const params = arr[1];
      const paramsArr = params.split('=');
      if (paramsArr.length === 2 && paramsArr[0] === 'referrer') {
        return paramsArr[1];
      }
    }
    return '';
  };

  const responseFacebook = (response: { hasOwnProperty: (arg0: string) => any; accessToken: any; status: string }) => {
    // let loginInfoStr = localStorage.getItem("loginInfo");
    // if (loginInfoStr) {
    //   let loginInfo = JSON.parse(loginInfoStr);
    //   console.log(loginInfo);
    //   if (loginInfo.appleLogin === true) {
    //     return
    //   }
    // }
    if (response.hasOwnProperty('id')) {
      const data = { accessToken: response.accessToken, referrer: '' };
      if (getReferrer() !== '') {
        data.referrer = getReferrer();
      }
      fetch(process.env.REACT_APP_SERVER_URL + '/login', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.hasOwnProperty('error')) {
              if (result.hasOwnProperty('message')) {
                setError(result.message);
              } else {
                setError(result.error);
              }
            } else {
              setError('');
              props.handleLogin(result);
              let obj = {
                facebookLogin: true,
                appleLogin: false,
                simpleLogin: false
              }
              localStorage.setItem('loginInfo', JSON.stringify(obj));
            }
          },
          (error) => {
            setError(error.message);
            console.log(error);
          }
        );
    } else {
      var error = 'Login error';
      if (response.hasOwnProperty('status')) {
        error = response.status;
      }
      setError(error);
    }
  };

  const handleAppleResponse = (response) => {


    const authorizationInfo = response.authorization;

    let userInfo = response?.user;

    let data = {};

    if (authorizationInfo) {
      if (userInfo) {
        data = { authorization: authorizationInfo, user: userInfo, referrer: 6 };
      } else {
        data = { authorization: authorizationInfo, referrer: 6 };
      }
      fetch(process.env.REACT_APP_SERVER_URL + '/login', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log('after successful login ===> ', result);
            props.handleLogin(result);
            let obj = {
              facebookLogin: false,
              appleLogin: true,
              simpleLogin: false
            }
            localStorage.setItem('loginInfo', JSON.stringify(obj));
          },
          (error) => {
            setError(error.message);
            console.log(error);
          }
        );
    } else {
      var error = 'Apple Login error';
      if (response.hasOwnProperty('status')) {
        error = response.status;
      }
      setError(error);
    }
  };

  const handleNameEntered = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(inputText);
    const inputName = e.target.value;
    setInputText(inputName);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      const requestOptions:any = {
        method: "GET",
        redirect: "follow"
      };

      fetch(  `${process.env.REACT_APP_SERVER_URL}/checkName?name=${encodeURIComponent(inputName)}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.message === "Available") {
            setPlaceholder("Add your real name here.....");
            setInputText(inputName);
            setName(inputName);
          } else if (data.message === "Not Available") {
            setPlaceholder("That name is taken, please try again.");
            setInputText("");       // Clear the input field to show placeholder
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
      }, 2000);
      setTypingTimeout(timeout);
  };

  const handleJoinClick = () => {

    let data = {
      "userName":name,
      "email":email,
      "referer": getReferrer()
    };

    fetch(process.env.REACT_APP_SERVER_URL + '/login', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject(res.json())
      )
      .then(
        (result) => {
          console.log('after successful login ===> ', result);
          props.handleLogin(result);
          let obj = {
            facebookLogin: false,
            appleLogin: false,
            simpleLogin: true
          }
          localStorage.setItem('loginInfo', JSON.stringify(obj));
        },
        (error) => {
          error.then((body)=>{
            setError(body.message);
          });
        }
      );
  };

  if (props.user.isLogged) {
    return <Redirect to="/home" />;
  } else {
    return (
      <main className={'main-bg'}>
        <div className="container">
          <MenuBar user={props.user} />
        </div>
        <div className="small-container">
          <div className="startpage-content">
            <div className="start-row d-flex full-height align-items-center">
              <section>
                <div className="about-content terms-content faq-content mb-4 pb-4 f-15">
                  <div className="terms-box bg-white">
                    {false && <h3 className="text-center lulo-bold about-title">Welcome</h3>}
                    {getReferrer() !== '' && (
                      <>
                        <p>
                          Welcome to the Yolomeal Referral Program, where you will be helping everyone instantly find
                          the best food and drink in your city.
                        </p>
                        <p>
                          Saving your 3 favorite menu items from local restaurants will earn you 2 shares in our
                          company. For each person that you refer who also saves their 3 favs you will receive an
                          additional share. 2 Million shares are up for grabs.
                        </p>
                        {/*<p>*/}
                        {/*  Login with Facebook and Apple below to start earning shares. We use only Facebook and Apple*/}
                        {/*  login because we need to be sure that only real people can become members. Thank you for your*/}
                        {/*  consideration.*/}
                        {/*</p>*/}
                      </>
                    )}
                    {getReferrer() === '' && (
                      <>
                        <p>
                          Welcome! Yolomeal is the place to save your favorite food finds from local restaurants and to
                          instantly find the best tasting food in your city.
                        </p>
                        {/*<p>*/}
                        {/*  We use only Log in with Facebook and Apple because we need to be sure that only real people*/}
                        {/*  become members. Thank you for your consideration.*/}
                        {/*</p>*/}
                      </>
                    )}
                  </div>
                </div>
              </section>

              <div>{error !== '' && <p className="danger">{error}</p>}</div>

              <input
                id="user-name"
                className="field md-filed text-center"
                placeholder={placeholder}
                value={inputText}
                onChange={handleNameEntered} // Update the state as the user types
              />
              <input
                id="user-email"
                className="field md-filed text-center"
                placeholder="Add email here....."
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="button"
                className="btn-primary"
                value="JOIN"
                onClick={handleJoinClick}
              />

              <h3> OR </h3>

              <div className="start-icons">
                <ul className="d-flex">
                  <li style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="">
                      <span className={loginButtonClass}>
                        {(fbInfo == false || showFbSignIn) && (
                          <FacebookLogin
                            appId={appId}
                            fields="email,first_name,last_name"
                            callback={responseFacebook}
                            disableMobileRedirect={true}
                          />
                        )}
                        <img style={{ display: 'none' }} src={AmazonImage} alt="Amazon" onClick={handleFacebookClick} />
                      </span>
                      <div className="apple-auth-div">
                        {(appleInfo == false || appleSignIn) && (
                          <AppleSignin
                            authOptions={authOptions}
                            uiType="dark"
                            className="apple-auth-btn"
                            buttonExtraChildren="Sign in with Apple"
                            onSuccess={(response: any) => handleAppleResponse(response)}
                            onError={(error: any) => console.error(error)}
                            skipScript={false}
                            render={renderAppleAuthButton}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  {/* {getReferrer() === '' && props.showBack && (
                    <li>
                      <button className="next-button" onClick={props.handleBack}>
                        BACK
                      </button>
                    </li>
                  )} */}
                  {false && getReferrer() === '' && props.showHome && (
                    <li>
                      <Link to="/home" className="mt-2 bttn1 see-others">
                        HOME
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              {false && getReferrer() === '' && (
                <div className="white">
                  Don't worry, this is only to confirm
                  <br />
                  that you are a real person.
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
};

LoginPage.defaultProps = defaultProps;

export default LoginPage;
