import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import LoaderContext from '../components/LoaderContext';

type Props = {
  isOpen: boolean;
  handleModalClose(): void;
  user: any
  yoloId: number
  handleSave(): void;
  updateSelectedCategory(categoryId): void;
  isChecked(categoryId): boolean
};

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  },
};

const ChangeCategoryModal: React.FC<Props> = (props) => {
  const [categories, setCategories] = useState<any[]>([]);
  const { showLoader, hideLoader } = useContext(LoaderContext);

  useEffect(() => {
    if(props.isOpen && categories.length < 1) {
       showCategory();
    }
  }, );

  const showCategory = async () => {
    const stored = localStorage.getItem('YoloMeal');
    if (stored) {
      showLoader()
      const userData = JSON.parse(stored);
      fetch(
        process.env.REACT_APP_SERVER_URL +
        '/user-categories?user=' +
        userData.id +
        '&v=' +
        Date.now(),
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + userData.token,
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            hideLoader()
            setCategories(result);
          },
          (error) => {
            hideLoader()
            console.log(error)
          }
        );
    }
  };

  const closeModel = ()=>{
    props.handleModalClose();
  }

  const updateSelectedCategory =  (categoryId) => {
    props.updateSelectedCategory(categoryId);
  }

  const isChecked = (categoryId) => {
    return props.isChecked(categoryId);
  };

  const handleSave = () => {
    props.handleSave();
  };


  return (
    <Modal isOpen={props.isOpen} onRequestClose={closeModel} contentLabel="Change Category Modal" style={customStyles}>
      <div id="element" className="small-container add-yolo-info text-center notification-settings">
        <div className="yolo-container text-center">
          <h2 className="futuralt-book mt-4 pt-2">Change category</h2>
        </div>

        {categories.map((category) => (
          <ul className="f-18 checkbox-list1 flex">
            <li key={category.id}>
              <input
                type="checkbox"
                name="category"
                value={category.id}
                onChange={() => updateSelectedCategory(category.id)}
                checked={isChecked(category.id)}
                disabled={category.counter >= 3}
                id={'category' + category.id}

              />
              <label htmlFor={'category' + category.id}>{category.name}</label>

            </li>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
              {category.counter === 3 && <span>Quota of 3 filled</span>}
            </div>
          </ul>
        ))}

        <div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
          <h1>
            <button className="next-button" onClick={closeModel}>Back</button>
          </h1>
          <h1>
            <button className="next-button" onClick={handleSave}>Save</button>
          </h1>
        </div>
      </div>
    </Modal>
  )
}

export default ChangeCategoryModal;