import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import LoaderContext from '../components/LoaderContext';
import MenuBar from '../components/Menubar';
import { Link } from 'react-router-dom';

type Props = {
    user: User,
    handleLogin(user: any): void
};
const NewMemberPage: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [users, setUsers] = useState([]);
    const [membersCount, setMembersCount] = useState(0);

    const [showJumpToTopButton, setShowJumpToTopButton] = useState(false);

    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());

    const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const selectedDate = new Date(value);
        setFrom(selectedDate);
    };

    const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const selectedDate = new Date(value);
        setTo(selectedDate);
    };

    const handleSearch = () => {
        loadUsers(); // Call loadUsers function to fetch data with selected date range
    };

    const handleScrol = () => {
        const windowHeight = window.innerHeight; // Height of the viewport
        const scrollPosition = window.scrollY; // Current scroll position
        const scrollThreshold = windowHeight * 0.25; // Threshold at 25% down the page

        setShowJumpToTopButton(scrollPosition > scrollThreshold); // Show button when scrolled past 25% down
    };

    // Function to handle clicking on "Jump to Top" button
    const handleJumpToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page smoothly
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrol);
        return () => {
            window.removeEventListener('scroll', handleScrol);
        };
    }, []);

    useEffect(() => {
        if (props.user.isLogged) {
            // loadUsers(); // Load users initially when component mounts
        }

        return () => {
            didCancel = true;
        };
    }, [props.user.isLogged]);

    let didCancel = false;

    const log = async (message: any) => {
        if (process.env.REACT_APP_LOG === 'true') {
            console.log(message);
        }
    };

    const handleErrors = (response: Response) => {
        log(response);
        if (!response.ok) {
            var err = response.statusText || 'Error';
            throw Error(err);
        }
        return response;
    };

    const loadUsers = async () => {
        showLoader();

        // Construct API endpoint with from and to dates as query parameters
        const url = new URL(process.env.REACT_APP_SERVER_URL + "/user/count");
        if (from) {
            url.searchParams.append('from', from.toISOString().split('T')[0] );
        }
        if (to) {
            url.searchParams.append('to', to.toISOString().split('T')[0] );
        }

        fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + props.user.token
            },
        })
          .then(handleErrors)
          .then(res => res.json())
          .then(
            (result) => {
                hideLoader();
                if (!didCancel) {
                    console.log(result);
                    setUsers(result.new_users);
                    setMembersCount(result.registered_users);
                }
            },
            (error) => {
                log(error);
                if (!didCancel) {
                    hideLoader();
                    setError(error.message);
                }
            }
          );
    };

    if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
        return (
          <div>
              <div>Access denied.</div>
          </div>
        )
    } else {
        return (
          <main>
              <div className="container mypage">
                  <MenuBar user={props.user} />
                  <section>
                      <div className="page-box-main">
                          {props.user.isLogged && props.user.id === 514 &&
                            <h2 className="futuralt-book pt-2 mb-4 text-center f-22">
                                <button className="next-button"> New members</button>
                            </h2>
                          }
                          {error !== '' &&
                            <p>{error}</p>
                          }

                          <div className="d-flex justify-content-space-between mb-10">
                              <div className="d-flex dateInputGroup">
                              <h2 style={{paddingRight:8}}>From:</h2>
                              <input
                                type="date"
                                value={from.toISOString().split('T')[0]}
                                onChange={handleFromDateChange}
                                max={new Date().toJSON().slice(0, 10)}
                              />
                              </div>
                              <div className="d-flex dateInputGroup">
                              <h2 style={{paddingRight:8}}>To:</h2>
                              <input
                                type="date"
                                value={to.toISOString().split('T')[0]}
                                onChange={handleToDateChange}
                                max={new Date().toJSON().slice(0, 10)}
                              />
                              </div>
                              <button className="btn-primary" onClick={handleSearch}>Search</button>
                          </div>

                          <div className="new-members-section" style={{marginTop:20}}>
                              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginTop:10,paddingBottom:10 }}>
                                  <h2>Members count:</h2>
                                  <h2>{membersCount}</h2>
                              </div>
                              <table className="shares-table">
                                  <colgroup>
                                      <col style={{ width: '20%' }} />
                                      <col style={{ width: '20%' }} />
                                      <col style={{ width: '20%' }} />
                                      <col style={{ width: '20%' }} />
                                      <col style={{ width: '20%' }} />
                                  </colgroup>
                                  <tr>
                                      <td><strong>Name</strong></td>
                                      <td><strong>Emails</strong></td>
                                      <td><strong>Platform</strong></td>
                                      <td><strong>Joining Date</strong></td>
                                      <td><strong>My Page</strong></td>
                                  </tr>
                                  {users.length > 0 ? (
                                    <>
                                        {users.map((item, i) => (
                                          <tr key={i}>
                                              <td>{item['firstName']} {item['lastName']}</td>
                                              <td>{item['email']}</td>
                                              <td>{item['platform']}</td>
                                              <td>{new Date(item['createdAt']).toISOString().split('T')[0]}</td>
                                              <td><Link to={"/user/"+item['id']}>View Now!</Link></td>
                                          </tr>
                                        ))}
                                    </>
                                  ) : (
                                    <tr>
                                        <td colSpan={2}>No users registered in the selected date range.</td>
                                    </tr>
                                  )}
                              </table>
                              <div>
                                  <button className="next-button black">
                                      <Link to="/admin">Back</Link>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </section>
                  {showJumpToTopButton && (
                    <button className="jump-to-top-button" onClick={handleJumpToTop}>Jump to Top</button>
                  )}
              </div>
          </main>
        );
    }
};

export default NewMemberPage;
