import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from "react-router-dom";

import MenuBar from './../components/Menubar';
import User from './../components/Utils';
import CategoryItems from '../components/CategoryItems';
import LoaderContext from './../components/LoaderContext';
import CopyUrl from './../components/CopyUrl';
import LoginPage from './LoginPage';

import BellImage from '../images/bell-icon.png';
import {categoriesArray} from '../utils/helpers';

type Props = {
	user: User,
	handleLogin(user): void
};

type MyItems = {
	upvoted: Number,
	received: Number,
	shares: Number,
	items: any[],
};

const MyPage: React.FC<Props> = (props) => {
	const [error, setError] = useState('');
	const [items, setItems] = useState<MyItems>({ upvoted: 0, received: 0, shares: 0, items: [] });
	const [upvotedItems, setUpvotedItems] = useState<any[]>([]);
	const { showLoader, hideLoader } = useContext(LoaderContext);
	const [showJumpToTopButton, setShowJumpToTopButton] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [showUpvoted, setShowUpvoted] = useState(false);

	let didCancel = false;

	const handleNextPage = () => {
		setPageNumber(pageNumber + 1);
	};

	const handlePreviousPage = () => {
		if (pageNumber > 0) {
			setPageNumber(pageNumber - 1);
		}
	};

	const log = async (message: any) => {
		if (process.env.REACT_APP_LOG === 'true') {
			console.log(message);
		}
	}

	const container = useRef<HTMLDivElement>(null)

	const handleScroll = () => {
		const windowHeight = window.innerHeight; // Height of the viewport
		const scrollPosition = window.scrollY; // Current scroll position
		const scrollThreshold = windowHeight * 1; // Threshold at 25% down the page

		setShowJumpToTopButton(scrollPosition > scrollThreshold); // Show button when scrolled past 25% down
	};

	const handleJumpToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page smoothly
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToBottom = ()=>{
		let check = localStorage.getItem("isUpvotedButtonClicked");
		console.log(check);

		if (check == "true") {
			setTimeout(()=>{
				if(container.current){
					window.scrollTo({
						top: container.current.scrollHeight, behavior: 'smooth'
					});
				}

			});
			setShowUpvoted(true);
			localStorage.setItem("isUpvotedButtonClicked", JSON.stringify(false));
		}
	}

	const handleErrors = (response) => {
		log(response);
		/*
		ok: false
		 redirected: false
		 status: 403
		 statusText: ""
		 type: "cors"
		*/
		if (!response.ok) {
			var err = response.statusText;
			if (err === '') {
				err = 'Error';
			}
			throw Error(err);
		}
		return response;
	}

	const loadData = async () => {
		showLoader();
		fetch(process.env.REACT_APP_SERVER_URL + "/my-page?v=" + Date.now(), {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Accept": "application/json",
				'Authorization': 'Bearer ' + props.user.token
			}),
		})
			.then(handleErrors)
			.then(res => res.json())
			.then(
				(result) => {
					log(result);
					if (!didCancel) {
						hideLoader();
						setItems({ upvoted: result.upvotedCount, received: result.receivedCount, shares: result.shareCount, items: result.items });
						setError("");
						scrollToBottom();
					}
				},
				(error) => {
					log(error);
					if (!didCancel) {
						hideLoader();
						setError(error.message);
					}
				}
			)
	};

	useEffect(() => {
		if (props.user.isLogged) {
			loadData();
			showUpvotedItems();
		}

		return () => {
			didCancel = true;
		};
	}, [props.user.isLogged,pageNumber,pageSize]);

	const handleUpvote = async (id) => {
	}

	const onLoginBackClick = async () => {
	}

	const handleLogin = async () => {
	}

	const showUpvotedItems = async () => {
		const stored = localStorage.getItem('YoloMeal');
		console.log("========>",stored);

		if (stored) {
			showLoader()
			const userData = JSON.parse(stored);
			fetch(
				process.env.REACT_APP_SERVER_URL +
				`/upvotedItems?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				{
					method: 'GET',
					headers: new Headers({
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: 'Bearer ' + userData.token,
					}),
				}
			)
				.then((res) => res.json())
				.then(
					(result) => {
						hideLoader();
						console.log("@@@@@@@@@@@@@@@",result);

						setUpvotedItems(result.items)

					},
					(error) => {
						hideLoader()
						console.log(error)
					}
				);
		}
	};

	const getCategoryItems = (category) => {
		let list: any[] = [];
		for (var i = 0; i < items.items.length; i++) {
			if (items.items[i].categoryId === category) {
				list.push(items.items[i]);
			}
		}
		return list;
	}

	const showupvoted = () => {
		setShowUpvoted(true);
		localStorage.setItem("isUpvotedButtonClicked", JSON.stringify(true));
	}

	const navigate =()=>{
		localStorage.setItem("isUpvotedButtonClicked", JSON.stringify(true));
	}

	var path = window.location.href;
	path = path.slice(0, -7);
	path = path + "user/" + props.user.id;

	if (!props.user.isLogged) {
		return (
			<LoginPage user={props.user} handleLogin={props.handleLogin} showBack={false} showHome={true} backgroundImage={true} handleBack={onLoginBackClick} />
		)
	} else {
		return (
			<main ref={container}>
				<div className="container mypage">
					<MenuBar user={props.user} />
					<section>
						<div className="mypage-title">
							<h1 className="text-center trend-sans"> {props.user.name} PAGE</h1>
							<div className="text-center">
								<Link className="tap-to-upvote-btn upvote_btn" to="/account">Click for Account Settings</Link>
							</div>
							<ul className="lulo-bold d-flex align-items-center uppercase justify-content-space-between f-16">
								<li>YOU OWN<span>{items.shares}</span>SHARES!</li>
								<li>YOU UPVOTED<span>{items.upvoted}</span>ITEMS!</li>
								<li>YOU HAVE<span>{items.received}</span>UPVOTES!</li>
							</ul>
						</div>
						<div className="page-box-main">
							<div className="notification-faq d-flex align-items-center justify-content-space-between hidden">
								<Link className="f-26 black lulo-bold" to="/settings">DASHBOARD</Link>
								<div className="my-notification text-right">
									<Link to="/notifications"><img src={BellImage} alt="notification" /></Link>
								</div>
							</div>
							{error !== '' &&
								<p>{error}</p>
							}
							{items.items.length > 0 &&
								<div>
									<CopyUrl path={path} page="mypage"></CopyUrl>
									{categoriesArray.map(category => (
										<CategoryItems
											key={category.id}
											items={getCategoryItems(category.id)}
											name={category.name}
											user={props.user}
											editable={true}
											handleLogin={handleLogin}
											handleUpvote={handleUpvote}
											loadData={loadData}
										/>
									))}
								</div>
							}
							<div   style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
								<button className="show-upvote" onClick={showupvoted}>SHOW MY UPVOTED ITEMS</button>
							</div>
							{showUpvoted && (
								<div  id="table"  className="table-container" style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
									<table className="shares-table" style={{ width: "70%" }}>
										<thead>
										<tr>
											<td><strong>Item No.</strong></td>
											<td><strong>Name</strong></td>
										</tr>
										</thead>
										<tbody>
										{upvotedItems.length <= 0 ? (
											<tr>
												<td colSpan={2} style={{ textAlign: 'center' }}>No items found</td>
											</tr>
										) : (
											upvotedItems.map((item, index) => (
												<tr key={index}>
													<td>{index + 1 + (pageNumber * pageSize)}</td>
													<td><Link to={`/restaurant/${item.restaurantId}`} style={{ color: 'inherit' }} onClick={navigate}>{item.name}</Link></td>
												</tr>
											))
										)}
										</tbody>
										<div style={{ marginTop: "20px" }}>
											<button style={{ padding: "10px 20px", background: "red", color: "white", border: "none", fontWeight: "bold", marginRight: "10px" }} onClick={handlePreviousPage} disabled={pageNumber === 0}>Previous</button>
											<button style={{ padding: "10px 30px", background: "#7bf1d1", border: "none", fontWeight: "bold", }} onClick={handleNextPage}>Next</button>
										</div>
									</table>
								</div>
							)}
						</div>

					</section>
				</div>

				{showJumpToTopButton && (
					<button className="jump-to-top-button" onClick={handleJumpToTop}>Jump to Top</button>
				)}
			</main>
		)
	}
}

export default MyPage;