import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";

import MenuBar from './../components/Menubar';
import User from './../components/Utils';
import LoaderContext from './../components/LoaderContext';

type Props = {
    user: User,
    handleLogin(user: any): void
};
const ShareHoldersPage: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [showJumpToTopButton, setShowJumpToTopButton] = useState(false);


	const handleScrol = () => {
		const windowHeight = window.innerHeight; // Height of the viewport
		const scrollPosition = window.scrollY; // Current scroll position
		const scrollThreshold = windowHeight * 1; // Threshold at 25% down the page
	
		setShowJumpToTopButton(scrollPosition > scrollThreshold); // Show button when scrolled past 25% down
	};

    // Function to handle clicking on "Jump to Top" button
    const handleJumpToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page smoothly
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScrol);
        return () => {
            window.removeEventListener('scroll', handleScrol);
        };
    }, []);	
    useEffect(() => {
        if (props.user.isLogged) {
            loadData();
        }

        return () => {
            didCancel = true;
        };
    }, [props.user.isLogged]);
    let didCancel = false;
    const history = useHistory();

    const log = async (message: any) => {
        if (process.env.REACT_APP_LOG === 'true') {
            console.log(message);
        }
    }
    const handleErrors = (response) => {
        log(response);
        if (!response.ok) {
            var err = response.statusText;
            if (err === '') {
                err = 'Error';
            }
            throw Error(err);
        }
        return response;
    }
    const loadData = async () => {
        showLoader();
        fetch(process.env.REACT_APP_SERVER_URL + "/shares?v=" + Date.now(), {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    log(result);
                    if (!didCancel) {
                        hideLoader();
                        setItems(result);
                        var total = 0;
                        result.map((item, i) => total = total + item['shareCount'])
                        setTotal(total);
                        setError("");
                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };
    if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
        return (
            <div>
                <div>Access denied.</div>
            </div>
        )
    } else {
        return (
            <main>
                <div className="container mypage">
                    <section>
                        <MenuBar user={props.user} />
                        <div className="page-box-main">
                            {props.user.isLogged && props.user.id === 514 &&
                                <h2 className="futuralt-book pt-2 mb-4 text-center f-22">
                                    <button className="next-button">Share holders  </button>
                                </h2>
                            }
                            {error !== '' &&
                                <p>{error}</p>
                            }
                            <div>
                                <h1>Share Holders List</h1>
                                <div className="table-container">
                                <table className="shares-table">

                                    <tr>
                                        <td><strong>First Name</strong></td>
                                        <td><strong>Last Name</strong></td>
                                        <td><strong>Yolos</strong></td>
                                        <td><strong>Referent</strong></td>
                                        <td><strong>Shares</strong></td>
                                    </tr>
                                    {items.map((item, i) =>
                                        <tr>
                                            <td>
                                                {item['firstName']}
                                            </td>
                                            <td>
                                                {item['lastName']}
                                            </td>
                                            <td align="right">
                                                {item['yoloCount']}
                                            </td>
                                            <td align="right">
                                                {item['refCount']}
                                            </td>
                                            <td align="right">
                                                {item['shareCount']}
                                            </td>

                                        </tr>


                                    )}
                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td align="right"><strong>{total}</strong></td>
                                    </tr>
                                </table>
                                </div>
                                <div>
                                    <button className="next-button black">
                                        <Link to="/admin">Back</Link>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </section>
                    
                </div>
                
                {showJumpToTopButton && (
                <button className="jump-to-top-button" onClick={handleJumpToTop}>Jump to Top</button>
            )}
            </main>
        );
    }
}

export default ShareHoldersPage;