import React from 'react';
import { useEffect } from "react";
import {
	Redirect
} from "react-router-dom";

type Props = { 
	user: any,
	handleLogout(): void 
};

const LogoutPage: React.FC<Props> = (props) => {

	useEffect(() => {
    return () => {
      // do nothing
    };
	}, []);	

	if (props.user.isLogged) {
		props.handleLogout();
	}
	return ( <Redirect to="/home"/> )
}

export default LogoutPage;