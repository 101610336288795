import React from 'react';
import {
  Link
} from "react-router-dom";

import WineImage from '../images/wine.jpg';
import MixedDrinkImage from '../images/mixed-drink.jpg';
import IceCreamImage from '../images/ice-crem-drink.jpg';
import CoffeeImage from '../images/coffee.jpg';
import BeerImage from '../images/beer.jpg';
import User from '../components/Utils';
import MenuBar from '../components/Menubar';

type Props = { 
	user: User,
};

const DrinksPage:  React.FC<Props> = (props) => {
	return (
		<main className="main-bg drink-bg container ">
			<MenuBar user={props.user}/>
			<div className="small-container drink-main">
				<div className="food-row full-height d-flex align-items-center text-center white sm-none">
					<div className="col-1 food-col">
						<div className="food-box"> 
							<Link to="/wine">
								<img src={WineImage} alt="wine"/>
								<h2>Wine</h2> 
							</Link>
						</div>
						<h1 className="sm-none"><Link to="/home">Home</Link></h1>
					</div>
					<div className="col-2 food-col">
						<div className="food-box">  
							<Link to="/mixed-drinks">
								<img src={MixedDrinkImage} alt="mixed-drink"/>
								<h2 className="small"> Mixed Drinks </h2>
							</Link>
						</div>
						<div className="food-box">
							<Link to="/other-drinks">
								<img src={IceCreamImage} alt="ice-crem-drink"/>
								<h2 className="other-drinks small mt-4"> Other <br/>Drinks </h2>
							</Link>
						</div>
						<div className="food-box"> 
							<Link to="/coffee">
								<img src={CoffeeImage} alt="coffee"/>
								<h2 className="small mt-2 coffee"> Coffee </h2>
							</Link>
						</div> 
					</div>
					<div className="col-3 food-col">
						<div className="food-box"> 
							<Link to="/beer">
								<img src={BeerImage} alt="beer"/>
								<h2> Beer </h2>  
							</Link>
						</div>
						<h1 className="sm-none"><Link to="/eat">Eat</Link></h1>
					</div>
				</div> 
				<div className="food-row full-height text-center white d-none sm-block">
					<div className="food-box"> 
						<Link to="/wine">
							<img src={WineImage} alt="wine"/>
							<h2>Wine</h2> 
						</Link>
					</div>
					<div className="food-box">  
						<Link to="/mixed-drinks">
							<img src={MixedDrinkImage} alt="mixed-drink"/>
							<h2 className="small"> Mixed Drinks </h2>
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/beer">
							<img src={BeerImage} alt="beer" />
							<h2>Beer</h2>
						</Link>
					</div>
					<div className="food-box">
						<Link to="/coffee">
							<img src={CoffeeImage} alt="coffee" />
							<h2 className="small mt-2 coffee">Coffee</h2>
						</Link>
					</div>
					<div className="food-box">
						<Link to="/other-drinks">
							<img src={IceCreamImage} alt="ice-crem-drink"/>
							<h2 className="other-drinks small mt-4">Other <br/>Drinks</h2>
						</Link>
					</div>
				</div>
				<div className="food-mobile-nav d-none sm-block">
					<nav className="d-flex align-items-center justify-content-center white">
						<Link to="/home">Home</Link>
						<Link to="/drinks">Drinks</Link>
					</nav>	
				</div> 
			</div>
		</main>
	);
}

export default DrinksPage;