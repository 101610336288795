import React from 'react';
import { Link } from 'react-router-dom';

type Props = {

}
const DisclaimerPage: React.FC<Props> = (props) => {
  return(
    <section className="w-full disclaimerMain d-flex align-items-center">
      <div className="disclaimer-main-sub">
        <p className="font-reklame" style={{ color: '#cb581f', fontSize: 26, marginTop: 40 }}>Yolomeal Contest conditions:</p >

        <ul className="font-poppins text-14 ml-15">
          <li className="mb-10 list-disc">To qualify, entrants must add one favorite menu item into Yolomeal.com.</li>
          <li className="mb-10 list-disc">Entrants must be a citizen of the United States.</li>
          <li className="mb-10 list-disc">The Apple watch prize will be awarded on 7/31/2024.</li>
          <li className="mb-10 list-disc">Winner will be notified by email after that date.</li>
          <li className="mb-10 list-disc">Contest void in states where prohibited.</li>
        </ul>

        <p className="font-poppins disclaimer"> This site is not part of the Facebook website or Facebook Inc.
          Additionally, this site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of META, Inc.
          For more info: <Link className="underline" to="/contest_disclaimer_and_conditions">Contest Conditions</Link> Contact us at: <a href="mailto:info@yolomeal.com" style={{color:'black'}} className='underline'>info@yolomeal.com</a></p>
      </div>
    </section>
);
}

export default DisclaimerPage;