import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import LoaderContext from '../components/LoaderContext';
import MenuBar from '../components/Menubar';
import { useParams, useLocation, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

type Props = {
    user: User,
    handleLogin(user: any): void
};
interface Params {
    id: string; // Define the type of the id parameter
    name: string
}

const AdminRestorentDetailPage: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showItemModal, setShowItemModal] = useState(false);
    const history = useHistory();
    const [itemIdToDelete, setItemIdToDelete] = useState('');

    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [items, setItems] = useState([]);
    const { id } = useParams<Params>();
    let location = useLocation();
    let name = new URLSearchParams(location.search).get("name");
    useEffect(() => {
        if (props.user.isLogged) {

            loadItems();
        }

        return () => {
            // didCancel = true;
        };
    }, [props.user.isLogged]);
    const log = async (message: any) => {
        if (process.env.REACT_APP_LOG === 'true') {
            console.log(message);
        }
    }
    const confirmBoxStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            height: '30%',
            margin: 0,
            padding: 0,
            border: 0,
            background: '#f9c5b4'
        }
    };
    const handleDeleteRestaurent = () => {
        setShowModal(true);
    }

    const closeConfirmBox = () => {
        setShowModal(false);
    }


    const handleConfirmYes = () => {
        deleteRestaurent();
        setShowModal(false);
        history.push('/restourent');
    };
    const handleConfirmNo = () => {
        setShowModal(false);
    };

    const handleDeleteItems = (id) => {
        setItemIdToDelete(id);
        setShowItemModal(true);
    }
    const handleItemConfirmYes = () => {
        deleteItems(itemIdToDelete);
        setShowItemModal(false);
    };

    const closeItemConfirmBox = () => {
        setShowItemModal(false);
    }


    const handleItemConfirmNo = () => {
        setShowItemModal(false);
    };

    const handleErrors = (response) => {
        log(response);
        if (!response.ok) {
            var err = response.statusText;
            if (err === '') {
                err = 'Error';
            }
            throw Error(err);
        }
        return response;
    }
    let didCancel = false;
    const loadItems = async () => {
        showLoader();
        fetch(process.env.REACT_APP_SERVER_URL + "/itemsWithYolos?restaurantId=" + id, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    hideLoader();
                    if (!didCancel) {
                        setItems(result.items);
                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };
    const deleteRestaurent = async () => {
        showLoader();
        fetch(process.env.REACT_APP_SERVER_URL + "/restaurant/delete?restaurantId=" + id, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    hideLoader();
                    if (!didCancel) {
                        

                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };

    const deleteItems = async (itemId) => {
        showLoader();
        fetch(process.env.REACT_APP_SERVER_URL + "/items/delete?itemId=" + itemId, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    hideLoader();
                    if (!didCancel) {
                        loadItems();
                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };

    if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
        return (
            <div>
                <div>Access denied.</div>
            </div>
        )
    } else {
        return (
          <main>
              <div className="container mypage">
                  <MenuBar user={props.user} />
                  <section>
                      <div className="page-box-main">
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <h1>{name}</h1>
                              <button className="deleteButton" onClick={handleDeleteRestaurent}>Delete</button>
                          </div>
                          <h2>Yolloed items</h2>
                          <table className="shares-table" style={{ width: '100%' }}>
                              <thead>
                              <tr>
                                  <td><strong>Name</strong></td>
                                  <td><strong>Yolo Count</strong></td>
                                  <td><strong>Action</strong></td>
                              </tr>
                              </thead>
                              <tbody>
                              {items.length > 0 ? (
                                items.map((item: any, index) => (
                                  <tr key={index}>
                                      <td>{item.itemName}</td>
                                      <td>{item.yoloCount}</td>
                                      <td>
                                          <button className='deleteItemButton' onClick={() => {handleDeleteItems(item.itemId)}}>Delete</button>
                                      </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                    <td colSpan={3} style={{ textAlign: 'center' }}>No items found.</td>
                                </tr>
                              )}
                              </tbody>
                          </table>
                      </div>
                      <div className="page-box-main">
                          <button className={'see-restaurant-button black font-bold'}>
                              <Link to="/admin">Back</Link>
                          </button>
                      </div>
                  </section>
              </div>

              <Modal
                isOpen={showModal}
                onRequestClose={closeConfirmBox}
                contentLabel="Confirmation"
                style={confirmBoxStyle}
              >
                  <div className="modal small-container add-yolo-info text-center notification-settings">
                      <div className="modal-content">
                          <p className='futuralt-book mt-4 pt-2'>This will delete this restaurant from Yolomeal. Are you
                              sure?</p>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <button className="next-button" onClick={handleConfirmYes}>Yes</button>
                              <button className="next-button" onClick={handleConfirmNo}>No</button>
                          </div>
                      </div>
                  </div>
              </Modal>
              <Modal
                isOpen={showItemModal}
                onRequestClose={closeItemConfirmBox}
                contentLabel="Confirmation"
                style={confirmBoxStyle}
              >
                  <div className="modal small-container add-yolo-info text-center notification-settings">
                      <div className="modal-content">
                          <p className='futuralt-book mt-4 pt-2'>This will delete this menu item from Yolomeal. Are you
                              sure?</p>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <button className="next-button" onClick={handleItemConfirmYes}>Yes</button>
                              <button className="next-button" onClick={handleItemConfirmNo}>No</button>
                          </div>
                      </div>
                  </div>
              </Modal>
          </main>
        );
    }
}
export default AdminRestorentDetailPage;