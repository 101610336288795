import React, {  useState } from 'react';
import { Link } from "react-router-dom";

import MenuBar from './../components/Menubar';
import FindLocationHome from '../components/FindLocationHome';
import FoodImage1 from '../images/food-1.jpg';
import FoodImage2 from '../images/food-2.jpg';
import FoodImage3 from '../images/food-3.jpg';
import FoodImage4 from '../images/food-4.jpg';
import FoodImage5 from '../images/food-5.jpg';
import FacebookIconIMG from '../images/fb-logo.png';
import InstagramIconIMG from '../images/insta-logo.png';

type Props = { 
	user: any,
  location: any,
  handleLocationChange(obj): void, 
};

const HomePage: React.FC<Props> = (props) => {
	const [showLocation, setShowLocation] = useState(false);

  const handlePlaceChanged = async (place) => {
    setShowLocation(false);
    props.handleLocationChange(place);
  };

	const handleHideLocation = async () => {
    setShowLocation(false)
  };
  
  const customStyles = {
    content: {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      border: 0,
      background: '#f9c5b4'
    },
  };

  return (
    <main className="main-bg">

      <div className="home-container">
        <MenuBar user={props.user} />
        <section className="find-menu">
          <div className="row">
            <div className="home-left-container">
              <h1 className="home-title">Yolomeal</h1>
              <p className="home-description">Find the best menu items in your city in two clicks + solve the "What
                should we order?!" problem once and for all</p>
            </div>
            <div className="home-right-container">
              <FindLocationHome location={props.location} handlePlaceChanged={handlePlaceChanged}
                                handleClose={handleHideLocation} />
              <ol>
                <li style={{listStyle: "auto"}}>Select your desired city above if not correct.</li>
                <li style={{listStyle: "auto"}}>Click the Eat button below to discover the tastiest menu items near you. Or, click Add Your Favorites below and share the joy with other food lovers.
                </li>
              </ol>
              <div className="home-btn-action">
                <div className="home-btn-group">
                  <Link className="home-btn" to="/eat">Eat</Link>
                  <Link className="home-btn" to="/add-yolo">Add Your Favorites</Link>
                </div>

              </div>
            </div>
          </div>
          <div className="home-food-ranking-container">
            <div className="home-food-ranking-title">
              <h3>Real, unbiased food rankings</h3>
            </div>
            <div className="home-food-images">
              <div className="home-image-item">
                <img src={FoodImage1} alt="food image" />
              </div>
              <div className="home-image-item">
                <img src={FoodImage2} alt="food image" />
              </div>
              <div className="home-image-item">
                <img src={FoodImage3} alt="food image" />
              </div>
              <div className="home-image-item">
                <img src={FoodImage4} alt="food image" />
              </div>
              <div className="home-image-item">
                <img src={FoodImage5} alt="food image" />
              </div>
            </div>
            <div className="d-flex justify-content-end social-icon">
              <a href="https://www.facebook.com/profile.php?id=100070811767472">
                <img src={FacebookIconIMG} alt="facebook icon" width={48} height={48} />
              </a>
              <a href="https://www.instagram.com/yolo.meal/">
                <img src={InstagramIconIMG} alt="instgram icon" width={48} height={48} />
              </a>
            </div>
          </div>

        </section>
      </div>

      {/* <Modal isOpen={showLocation} ariaHideApp={false} style={customStyles}>
        <FindLocation location={props.location} handlePlaceChanged={handlePlaceChanged} handleClose={handleHideLocation} />
      </Modal> */}
    </main>
  );
}

export default HomePage;
