import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { tagArray } from '../utils/helpers';

type Props = {
  isOpen: boolean;
  handleModalClose(): void;
  user: any;
  yoloId: number;
  handleTagSave(): void;
  items: any;
  selectedItemCategory: any;
  selectedItemId: any;
  selectedTags: any;
  tags: any
  onTagClick(id):void
};

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  },
};

const AddMoreTagsModal: React.FC<Props> = (props) => {

  const closeModel = ()=>{
    console.log("close clicked")
    props.handleModalClose();
  }

  const hideTags = () => {
    closeModel();
  }

  const onTagClick = (id) => {
    props.onTagClick(id);
  };

  const handleTagSave = () => {
    props.handleTagSave();
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModel}
      style={customStyles}
    >
      <div className="small-container add-yolo-info text-center notification-settings">
        <div className="yolo-container text-center">
          <h2 className="futuralt-book mt-4 pt-2">Add Tags</h2>
          <h2 className="futuralt-book pt-2 mb-2">CHECK ANY THAT APPLY</h2>
        </div>
        <ul className="f-18 checkbox-list">
          {props.tags.map((tag) => (
            <li key={tag.id}>
              <input
                type="checkbox"
                id={'tags' + tag.id}
                onChange={() => onTagClick(tag.id)}
                checked={props.selectedTags.includes(tag.id)}
              />
              <label htmlFor={'tags' + tag.id}>{tag.name}</label>
            </li>
          ))}
        </ul>
        <div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
          <h1>
            <button className="next-button" onClick={hideTags}>Back</button>
          </h1>
          <h1>
            <button className="next-button" onClick={handleTagSave}>Save</button>
          </h1>
        </div>
      </div>
    </Modal>
  )
}

export default AddMoreTagsModal;