import React from 'react';
import { NavLink } from "react-router-dom";

import MenuBar from './../components/Menubar';

type Props = { 
	user: any
};

const AboutPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg  about-page">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="about-content terms-content policy-content">  
						<div className="terms-box bg-white"> 
							<p className="mt-4 pb-4 f-20 avenir">
								Yolomeal tells you where to get the tastiest food in town in just 2 clicks. 
								<br/><br/>
								Simple.
								<br/><br/>
								Delicious.
							</p>
							<div className="page-link avenir f-20"><NavLink to="/terms">Terms of use</NavLink></div>
							<div className="page-link avenir f-20"><NavLink to="/policy">Privacy policy</NavLink></div>
							<div className="page-link avenir f-20"><a href="mailto:info@yolomeal.com">Contact Us</a></div>
						</div>
					</div>
				</section> 
			</div>
		</main>
	);
}

export default AboutPage;