import React from 'react';
import { useEffect, useState } from 'react';

type Props = {
  location: any;
  handlePlaceChanged(place): void;
  handleClose(): void;
};

const FindLocation: React.FC<Props> = (props) => {
  const [error, setError] = useState('');
  const [location, setLocation] = useState({ lat: 0, lng: 0, name: '' });

  const newLocation = async (place, name) => {
    var obj = {
      name: name,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setLocation(obj);
    setError('');
    //console.log(obj);
    props.handlePlaceChanged(obj);
  };

  useEffect(() => {
    const center = { lat: props.location.lat, lng: props.location.lng };

    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };

    const input = document.getElementById('restaurant-name') as HTMLInputElement;

    const options = {
      bounds: defaultBounds,
      fields: ['address_components', 'geometry'],
      origin: center,
      strictBounds: false,
      types: ['geocode'],
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener('place_changed', function () {
      var place = autocomplete.getPlace();
      //console.log(place);
      if (place.geometry && place.address_components) {
        var i;
        for (i = 0; i < place.address_components.length; i++) {
          if (place.address_components[i]['types'][0] === 'locality') {
            newLocation(place, place.address_components[i]['long_name']);
            return;
          }
        }
        for (i = 0; i < place.address_components.length; i++) {
          if (place.address_components[i]['types'][0] === 'administrative_area_level_2') {
            newLocation(place, place.address_components[i]['long_name']);
            return;
          }
        }
      }
      setLocation({ lat: 0, lng: 0, name: '' });
      setError('Not Found!');
    });
  }, []);

  const onClose = async () => {
    props.handleClose();
  };

  const onChange = async () => {
    props.handlePlaceChanged(location);
  };

  return (
    <div className="container bg-orange">
      <section>
        <div className="small-container add-yolo-info">
          <div className="yolo-container">
            <h2 className="futuralt-book mt-4 pt-2 mb-2 text-center">{props.location.name}</h2>
            <input
              autoFocus
              id="restaurant-name"
              className="field xl-filed text-center mt-4 mb-4"
              placeholder="ENTER LOCATION HERE"
            />
            {error !== '' && <h2 className="futuralt-book text-center">{error}</h2>}
            {location.name !== '' && <h2 className="futuralt-book text-center">{location.name}</h2>}
          </div>
          <div className="mt-0 mb-4 d-flex-buttons black justify-content-space-between align-items-center uppercase">
            <h1>
              <button className="next-button" onClick={() => onClose()}>
                BACK
              </button>
            </h1>
            {location.name !== '' && (
              <h1>
                <button className="next-button" onClick={() => onChange()}>
                  CHANGE
                </button>
              </h1>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FindLocation;
