import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import ItemNew from './../components/ItemNew';
import LoaderContext from './../components/LoaderContext';
import LoginPage from './LoginPage';
import { tagArray } from '../utils/helpers'
import MenuBar from "../components/Menubar";

type Props = { 
	name: String,
  id: Number,
	eatOrMeal: String,
	location: any,
	user: any
	handleLogin(user): void,	
};

const EatYolosPage: React.FC<Props> = (props) => {
  const ref = useRef(null);
	const [error, setError] = useState('');
  const [nearByItems, setNearByItems] = useState<any[]>([]);
  const [topItems, setTopItems] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
	const { showLoader, hideLoader } = useContext(LoaderContext);
	const [showJumpToTopButton, setShowJumpToTopButton] = useState(false);

	const handleScrolBottomToTop = () => {
		const windowHeight = window.innerHeight; // Height of the viewport
		const scrollPosition = window.scrollY; // Current scroll position
		const scrollThreshold = windowHeight * 1; // Threshold at 25% down the page
	
		setShowJumpToTopButton(scrollPosition > scrollThreshold); // Show button when scrolled past 25% down
	};

	const handleJumpToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page smoothly
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScrolBottomToTop);
		return () => {
			window.removeEventListener('scroll', handleScrolBottomToTop);
		};
	}, []);

	useEffect(() => {
		loadItems(tags);
	}, [props.user.isLogged]);

  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  }

	const handleUpvote = async (id) => {
		showLoader();
		const data = { userId: props.user.id};
		fetch(process.env.REACT_APP_SERVER_URL + "/items/" + id + "/upvote", {
			method: 'POST',
			headers:  new Headers({
				'Content-Type': 'application/json',
				"Accept":"application/json",
				'Authorization': 'Bearer ' + props.user.token
			}),
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(
			(result) => {
				log(result);
				setNearByItems(nearByItems.map(item => item.id === id ? {...item, upvoted: true, upvoteCount: item.upvoteCount + 1} :  item ));
				setTopItems(topItems.map(item => item.id === id ? {...item, upvoted: true, upvoteCount: item.upvoteCount + 1} :  item ));
				setError("");
				hideLoader();
			},
			(error) => {
				log(error);
				setError(error.message);
				hideLoader();
			}
		)		
	}

	const RemoveUpvote = async (id) => {
		showLoader();
		const data = { userId: props.user.id};
		fetch(process.env.REACT_APP_SERVER_URL + "/items/" + id + "/removeUpvote", {
			method: 'POST',
			headers:  new Headers({
				'Content-Type': 'application/json',
				"Accept":"application/json",
				'Authorization': 'Bearer ' + props.user.token
			}),
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(
			(result) => {
				log(result);
				setNearByItems(nearByItems.map(item => item.id === id ? {...item, upvoted: false, upvoteCount: item.upvoteCount - 1} :  item ));
				setTopItems(topItems.map(item => item.id === id ? {...item, upvoted: false, upvoteCount: item.upvoteCount - 1} :  item ));
				setError("");
				hideLoader();
			},
			(error) => {
				log(error);
				setError(error.message);
				hideLoader();
			}
		)		
	}

	const onTagClick = async (id) => {
		var newTags;
		if (tags.includes(id)) {
			newTags = tags.filter(function (tag) { return tag !== id });
			setTags(newTags);
		} else {
			newTags = [...tags, id];
			setTags(newTags);
			handleScroll(ref.current)
		}
		loadItems(newTags);
	}
	
	const handleScroll = (ref) => {
		// ref.current?.scrollIntoView({behavior: 'smooth'});	
		window.scrollTo({
			top: ref.offsetTop,
			left: 0,
			behavior: "smooth",
		});
	};

	const loadItems = (newTags: Array<any>) => {
		showLoader();

		var headers = new Headers({
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		);
		if (props.user.isLogged) {
			headers.append('Authorization', 'Bearer ' + props.user.token);
		}
		fetch(process.env.REACT_APP_SERVER_URL + "/yolos?category=" + props.id + "&latitude=" + props.location.lat + "&longitude=" + props.location.lng + "&tags=" + newTags.join() + "&v=" + Date.now(), {
			method: 'GET',
			headers: headers
		})
		.then(res => res.json())
		.then(
			(result) => {
				log(result);
				setNearByItems(result.nearBy);
				setTopItems(result.top);
				/*
				setCategory({id: id, name: name})
				showItem();
				*/
				setError("");
				hideLoader();
				setShowLogin(false);
			},
			(error) => {
				log(error);
				setError(error.message);
				hideLoader();
			}
		)
	}

	const onLoginClick = async () => {
		setShowLogin(true);
	}

	const onLoginBackClick = async () => {
		setShowLogin(false);
	}

	const handleNotificationClick= async(id)=>{
			sendNotification(id);
	}

	const sendNotification = (id) => {
		try {
			const stored = localStorage.getItem('YoloMeal');
			console.log(stored);
			if (stored) {
				showLoader();
				const userData = JSON.parse(stored);
				fetch(
					process.env.REACT_APP_SERVER_URL +
					`/itemExpired?itemId=${id}&userId=${userData.id}` ,
					{
						method: 'GET',
						headers: new Headers({
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + userData.token,
						})
					}
				)
					.then(
						(result) => {

							if(nearByItems != null)
							{
								setNearByItems(
									nearByItems.map((item) => (item.id === id ? { ...item, expired: true} : item))
								)
								console.log(nearByItems);
							}
							else
							{
								setTopItems(
									topItems.map((item) => (item.id === id ? { ...item, expired: true} : item))
								)
								console.log(topItems);
							}

							hideLoader();
						},
						(error) => {
							hideLoader();
							console.log(error)
						}
					);

			}
		} catch (error) {
			console.error('Error fetching categories:', error);
		}
	};

	const loadData = () => {
		loadItems(tags);
	}

	var mainClass = "main-bg eat-bg ";
	var back = <Link to="/eat">Eat</Link>
	var titleClass = "text-center pt-3 pb-2 uppercase";
	var helpClass = "f-38 light text-center font-poppins";
	if (props.eatOrMeal === 'drink') {
		mainClass = "main-bg drink-bg ";
		back = <Link to="/drinks">Drinks</Link>
		titleClass = "text-center pt-3 pb-2 uppercase white";
		helpClass = helpClass + " white";
	}

	const tagList = tagArray.filter(el => el.category == props.id).map((tag) => {
		if (tags.includes(tag.id)) {
			return <li className="tag-filter" key={tag.id}><input type="checkbox" id={"tags" + tag.id} onClick={() => onTagClick(tag.id)} checked/><label htmlFor={"tags" + tag.id}>{tag.name}</label></li>
		} else {
			return <li className="tag-filter" key={tag.id}><input type="checkbox" id={"tags" + tag.id} onClick={() => onTagClick(tag.id)}/><label htmlFor={"tags" + tag.id}>{tag.name}</label></li>
		}
	});

	if (showLogin && !props.user.isLogged) {
		return ( 
			<LoginPage user={props.user} handleLogin={props.handleLogin} showBack={true} showHome={false} backgroundImage={true} handleBack={onLoginBackClick}/>
		)
	} else {
		return (
			<main className={mainClass}>
				<div className="container">
				<MenuBar user={props.user} />
				</div>
				<div className="eat-main notification-settings">
					<h1 className={titleClass}>{props.name}</h1>
					{tagList.length > 0 && !showFilter &&
						<div className="my-pagebox tag-filter-panel pink-button text-center" onClick={() => setShowFilter(true)}>
							Click here to filter your choices
						</div>
					}
					{tagList.length > 0 && showFilter &&
						<div className="my-pagebox tag-filter-panel">
							<ul className="f-18 checkbox-list">
								{tagList}
							</ul>
						</div>
					}
					<div ref={ref} />
					{nearByItems.length === 0 &&
						<h3 className={helpClass}>Help everyone find the BEST by saving one of your TOP 3 here!</h3>
					}
					<div className="categoryItem card-container">
						{nearByItems.map((item) =>
							<div className="categoryItemWidth">
								<ItemNew
									handleLogin={onLoginClick}
									item={item}
									editable={props.user.id == 6 || props.user.id == 7 || props.user.id == 514} isLogged={props.user.isLogged}
									user={props.user}
									showRestaurant={true}
									handleUpvote={handleUpvote}
									handleRemoveUpvote={RemoveUpvote}
									handleNotificationClick={handleNotificationClick}
									restaurentPage={false}
									loadData={loadData}
								/>
							</div>
						)}
					</div>

					{nearByItems.length === 0 &&
						<div>
							<div className="mt-4 white ">
								<h1 className="text-center pt-3 pb-2 uppercase"><Link to="/add-yolo">Add Favorite</Link></h1>
							</div>
						</div>
					}

					{topItems.length > 0 &&
						<h3 className={helpClass}>You can be the first to help everyone find a great meal by saving you favorite here!</h3>
					}
					<div className="small-container mt-4 d-flex white justify-content-space-between align-items-center pb-2 uppercase">
						<h1><Link to="/home">Home</Link></h1>
						<h1>{back}</h1>
					</div>
					{error !== '' &&
						<p>{error}</p>
					}
				</div>
				{showJumpToTopButton && (
					<button className="jump-to-top-button" onClick={handleJumpToTop}>Jump to Top</button>
				)}
			</main>
		);
	}
}

export default EatYolosPage;
