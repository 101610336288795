import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import LoaderContext from '../components/LoaderContext';
import MenuBar from '../components/Menubar';
import { useParams, useLocation, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

type Props = {
  user: User,
  handleLogin(user: any): void
};
interface Params {
  id: string; // Define the type of the id parameter
  name: string
}

const AdminItemNoLongerPage: React.FC<Props> = (props) => {
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showYoloModal, setShowYoloModal] = useState(false);
  const history = useHistory();
  const [yoloIdToDelete, setYoloIdToDelete] = useState('');

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [yolos, setYolos] = useState([]);
  const [restaurant, setRestaurant] = useState('');
  const [upvote, setUpvote] = useState('');
  const [category, setCategory] = useState('');

  const { id} = useParams<Params>();
  let location = useLocation();
  let name = new URLSearchParams(location.search).get("name");

  useEffect(() => {
    if (props.user.isLogged) {
      loadItems();
    }

    return () => {
      // didCancel = true;
    };
  }, [props.user.isLogged]);
  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  }
  const confirmBoxStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      height: '30%',
      margin: 0,
      padding: 0,
      border: 0,
      background: '#f9c5b4'
    }
  };
  const handleDeleteItem = () => {
    setShowModal(true);
  }

  const closeConfirmBox = () => {
    setShowModal(false);
  }


  const handleConfirmYes = () => {
    deleteItem();
    setShowModal(false);
    history.push('/markedItemExpired');
  };
  const handleConfirmNo = () => {
    setShowModal(false);
  };

  const handleDeleteYolos = (id) => {
    setYoloIdToDelete(id);
    setShowYoloModal(true);
  }
  const handleYoloConfirmYes = () => {
    deleteYolos(yoloIdToDelete);
    setShowYoloModal(false);
  };

  const closeYoloConfirmBox = () => {
    setShowYoloModal(false);
  }

  const handleYoloConfirmNo = () => {
    setShowYoloModal(false);
  };

  const handleErrors = (response) => {
    log(response);
    if (!response.ok) {
      var err = response.statusText;
      if (err === '') {
        err = 'Error';
      }
      throw Error(err);
    }
    return response;
  }

  let didCancel = false;

  const loadItems = async () => {
    showLoader();
    fetch(process.env.REACT_APP_SERVER_URL + "/getItemInfo?itemId=" + Number(id) , {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + props.user.token
      }),
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(
        (result) => {
          hideLoader();
          setRestaurant(result.restaurantName);
          setUpvote(result.upvoteCount);
          setCategory(result.categoryId);
          if (!didCancel) {
            setYolos(result.yolos);
          }
        },
        (error) => {
          log(error);
          if (!didCancel) {
            hideLoader();
            setError(error.message);
          }
        }
      )
  };

  const deleteItem = async () => {
    showLoader();
    fetch(process.env.REACT_APP_SERVER_URL + "/items/delete?itemId=" + Number(id), {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + props.user.token
      }),
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(
        (result) => {
          hideLoader();
          if (!didCancel) {
          }
        },
        (error) => {
          log(error);
          if (!didCancel) {
            hideLoader();
            setError(error.message);
          }
        }
      )
  };

  const deleteYolos = async (yoloId: string) => {
    showLoader();
    fetch(process.env.REACT_APP_SERVER_URL + `/yolos/delete/` + yoloId, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + props.user.token
      }),
    })
      .then(handleErrors)
      .then(res => res.json())
      .then(
        (result) => {
       //   console.log(result)
          if (!didCancel) {
            loadItems();
          }
          hideLoader();
        },
        (error) => {
          log(error);
          if (!didCancel) {
            hideLoader();
            setError(error.message);
          }
        }
      )
  };

  if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
    return (
      <div>
        <div>Access denied.</div>
      </div>
    )
  } else {
    return (
      <main>
        <div className="container mypage">
          <MenuBar user={props.user} />
          <section>
            <div className="page-box-main">
              <h3> 👇 Item needs to be deleted as it is no longer available!</h3>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>'{name}'</h2>
                <button className="deleteButton" onClick={handleDeleteItem}>Delete</button>
              </div>
              <p>Offered By <b>{restaurant}</b></p>
              <p> - Belongs To The Category <b>{category}</b></p>
              <p> - Liked By <b>{upvote} people</b></p>

              <h3>Item's All YOLOs</h3>
              <table className="shares-table" style={{ width: '170%' }}>
                <thead>
                <tr>
                  <td><strong>Yolo</strong></td>
                  <td><strong>User_Id</strong></td>
                  <td><strong>Action</strong></td>
                </tr>
                </thead>
                <tbody>
                {yolos.length > 0 ? (
                  yolos.map((yolo: any, index) => (
                    <tr key={index}>
                      <td style={{ width: '30%' }}>{yolo.description}</td>
                      <td style={{ width: '20%' }}>{yolo.userId}</td>
                      <td>
                        <button className="deleteItemButton" onClick={() => {handleDeleteYolos(yolo.id);}}>Delete</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} style={{ textAlign: 'center' }}>No yolo found.</td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
            <div className="page-box-main">
              <button className={'see-restaurant-button black font-bold'}>
                <Link to="/admin">Back to Admin Page</Link>
              </button>
            </div>
          </section>
        </div>

        <Modal
          isOpen={showModal}
          onRequestClose={closeConfirmBox}
          contentLabel="Confirmation"
          style={confirmBoxStyle}
        >
          <div className="modal small-container add-yolo-info text-center notification-settings">
            <div className="modal-content">
              <p className='futuralt-book mt-4 pt-2'>This will delete this ITEM from Yolomeal. Are you sure?</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="next-button" onClick={handleConfirmYes}>Yes</button>
                <button className="next-button" onClick={handleConfirmNo}>No</button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showYoloModal}
          onRequestClose={closeYoloConfirmBox}
          contentLabel="Confirmation"
          style={confirmBoxStyle}
        >
          <div className="modal small-container add-yolo-info text-center notification-settings">
            <div className="modal-content">
              <p className='futuralt-book mt-4 pt-2'>This will delete this YOLO from Yolomeal. Are you sure?</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="next-button" onClick={handleYoloConfirmYes}>Yes</button>
                <button className="next-button" onClick={handleYoloConfirmNo}>No</button>
              </div>
            </div>
          </div>
        </Modal>
      </main>
    );
  }
}
export default AdminItemNoLongerPage;