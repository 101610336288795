import { useEffect, useState } from 'react';
import LocationPointer from '../images/location-pointer.svg';

type Props = {
  location: any;
  handlePlaceChanged(place): void;
  handleClose(): void;
};

const FindLocationHome: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const [location, setLocation] = useState({ lat: 0, lng: 0, name: '' });
    const [showLocation, setShowLocation] = useState(true);

    const newLocation = async (place, name) => {
      var obj = {
        name: name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLocation(obj);
      setError('');
      //console.log(obj);
      setShowLocation(false);
      props.handlePlaceChanged(obj);
    };

    const handlePlaceChange = (place) =>{
      if (place.geometry && place.address_components) {

        const addressComponents = place.address_components;
        let fullName : String = '';

        const country = addressComponents.filter(component =>
          component['types'].includes('country')
        ).map(component => {
          return component;
        });

        const state = addressComponents.filter(component =>
          component['types'].includes('administrative_area_level_1')
        ).map(component => {
          return component;
        });

        const locality = addressComponents.filter(component =>
          component['types'].includes('locality')
        ).map(component => {
          return component;
        });

        if (locality.length > 0){
          fullName = `${locality[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`
          newLocation(place, fullName);
          return;
        }

        const administrativeArea = addressComponents.filter(component =>
          component['types'].includes('administrative_area_level_2')
        ).map(component => {
          return component;
        });

        if (administrativeArea.length > 0) {
          fullName = `${administrativeArea[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`
          newLocation(place, fullName);
          return;
        }
      }
      setLocation({ lat: 0, lng: 0, name: '' });
      setError('Not Found!');
    }

    useEffect(() => {
      const center = { lat: props.location.lat, lng: props.location.lng };
  
      const defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
  
      const input = document.getElementById('restaurant-name') as HTMLInputElement;
  
      const options = {
        bounds: defaultBounds,
        fields: ['address_components', 'geometry'],
        origin: center,
        strictBounds: false,
        types: ['geocode'],
      };
  
      const autocomplete = new google.maps.places.Autocomplete(input, options);
  
      autocomplete.addListener('place_changed', function () {
        var place = autocomplete.getPlace();
        handlePlaceChange(place);
      });
    }, []);

    return (
        <div className="container">
          <section>
            <div className="d-flex align-items-centers">
                <input
                  id="restaurant-name"
                  className="field xl-filed"
                  placeholder={props.location.name ? props.location.name : "Enter your desired city."}
                />
              <div className="search-icon h-100 bg-white" >
                <img src={LocationPointer} alt="Location Icon"  width={40} height={40} />
              </div>
            </div>
            {error !== '' && <h2 className="futuralt-book text-center">{error}</h2>}
                {location.name !== '' && showLocation && <h2 className="futuralt-book text-center">{location.name}</h2>}
          </section>
        </div>
      );
}
export default FindLocationHome;