import React, { useState } from 'react';
import Modal from 'react-modal';

type Props = {
  isOpen: boolean;
  handleModalClose(): void;
  user: any
  yoloId: number
  leftCounter: any;
  description: any;
  userName: any;
  onUserNameChange(event) : any;
  onDescriptionChange(event): any;
  onEdit(): void
};

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  },
};

const EditYoloDescriptionModal: React.FC<Props> = (props) => {
  const [error, setError] = useState('');

  const closeModel = ()=>{
    props.handleModalClose();
  }

  const onDescriptionChange = (event) => {
   props.onDescriptionChange(event);
  }

  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  }

  const onEdit = async () => {
    props.onEdit();
  }

  const onUserNameChange =  (event) => {
    props.onUserNameChange(event);
  }


  return (
    <Modal isOpen={props.isOpen} ariaHideApp={false} style={customStyles}>
      <div className="container bg-orange">
        <section>
          <div className="small-container add-yolo-info text-center">
            <div className="yolo-container  ">
              <h2 className="futuralt-book mt-4 pt-2 mb-4 text-center f-22"> ADD YOLO TEXT </h2>
              <ul>
                <li className="tell-us">
                  <textarea autoFocus maxLength={300} rows={6} placeholder="Tell us why you love it!"
                            onChange={onDescriptionChange} value={props.description}></textarea>
                  <small>{props.leftCounter}</small>
                </li>
              </ul>
              {props.user.isLogged && props.user.id === 20 &&
                <input className="field xl-filed text-center mt-4 mb-4"
                       placeholder="USER NAME" value={props.userName}
                       onChange={onUserNameChange} />
              }
            </div>
            {error !== '' &&
              <p>{error}</p>
            }
            <div className="mt-0 mb-4 d-flex-buttons black justify-content-space-between align-items-center uppercase">
              <h1>
                <button className="next-button" onClick={closeModel}>BACK</button>
              </h1>
              <h1>
                <button className="next-button" onClick={() => onEdit()}>SAVE YOLO</button>
              </h1>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default EditYoloDescriptionModal;