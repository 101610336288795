import React, { useContext, useState } from 'react';
import FindRestaurant from './../components/FindRestaurant';
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Import useHistory hook for navigation
import MenuBar from './../components/Menubar';
import LoaderContext from '../components/LoaderContext';

type Props = {
  location: any; // Location data, if needed
  user: any;
};

async function fetchRestaurantId(restaurantName) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/restaurants?name=${encodeURIComponent(restaurantName)}`
    );
    return { data: response.data, exists: true };
  } catch (error) {
    return { exists: false };
  }
}

const RestaurantSearch: React.FC<Props> = (props) => {
  const [place, setPlace] = useState({ name: '', address: '', latitude: '', longitude: '' });
  const [error, setError] = useState('');
  const history = useHistory();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const handlePlaceChanged = async (place) => {
    setError('');
    setPlace(place); // Update place state with selected restaurant details
    showLoader()
    const result = await fetchRestaurantId(place.name);
    if (result) {
      if (result.exists) {
        history.push(`/restaurant/${result.data}`);
        hideLoader();
      } else {
        setError('There are no Yolos for this restaurant. Add a Yolo if you find something amazing there.');
        hideLoader();
      }
    } else {
      setError('Error fetching restaurant information. Please try again.');
      hideLoader();
    }
  };

  const handlePlaceError = (error) => {
    setError(error);
  };

  return (
    <main>
      <div className="container">
        <MenuBar user={props.user} />
        <section>
          <div className="small-container text-center">
            <FindRestaurant
              location={props.location}
              handlePlaceChanged={handlePlaceChanged}
              handlePlaceError={handlePlaceError}
            />
            {error && <p className="error-message">{error}</p>}
          </div>
        </section>
      </div>
    </main>
  );
};

export default RestaurantSearch;
