import React from 'react'
import {
  Link
} from "react-router-dom"

import BurgersImage from '../images/new-burger.png'
import HandheldImage from '../images/handhelds.jpg'
import SidesImage from '../images/new-side.png'
import EntreeImage from '../images/new-entree.png'
import BreakfastImage from '../images/new-breakfast.png'
import DessertImage from '../images/pie.jpg'
import PizzaImage from '../images/new-pizza.png'
import AppsImage from '../images/new-appetizer.png'
import User from '../components/Utils'
import MenuBar from '../components/Menubar'

type Props = { 
	user: User,
};

const EatPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg eat-bg container">
			<MenuBar user={props.user}/>
			<div className="small-container eat-main">
				<div className="food-row full-height d-flex align-items-center text-center white sm-none">
					<div className="col-1 food-col">
						<div className="food-box"> 
							<Link to="/burgers">
								<img src={BurgersImage} alt="burgers" />
								<h2> Burgers </h2> 
							</Link>
						</div>
						<div className="food-box"> 
							<Link to="/handheld">
								<img src={HandheldImage} alt="handheld" />
								<h2> HANDHELD </h2>
							</Link>
						</div>
						<div className="food-box"> 
							<Link to="/breakfast">
								<img src={BreakfastImage} alt="breakfast" />
								<h2> Breakfast </h2> 
							</Link>
						</div>
						<h1 className="sm-none"><Link to="/home">Home</Link></h1>
					</div>
					<div className="col-2 food-col">
						<div className="food-box">  
							<Link to="/entree">
								<img src={EntreeImage} alt="entree" />
								<h2> Entree </h2>
							</Link>
						</div>
						<div className="food-box"> 
							<Link to="/dessert">
								<img src={DessertImage} className="desert-Image" alt="dessert" />
								<h2 className="desert-h2"> Dessert </h2>
							</Link>
						</div> 
					</div>
					<div className="col-3 food-col">
						<div className="food-box"> 
							<Link to="/pizza">
								<img src={PizzaImage} alt="Pizza" />
								<h2> Pizza </h2> 
							</Link>
						</div>
						<div className="food-box"> 
							<Link to="/apps">
								<img src={AppsImage} alt="apps" />
								<h2> Apps </h2> 
							</Link>
						</div>
						<div className="food-box"> 
							<Link to="/sides">
								<img src={SidesImage} alt="sides" />
								<h2> Sides </h2> 
							</Link>	
						</div>
						<h1 className="sm-none">
							<Link to="/drinks">Drink</Link>
						</h1> 
					</div>
				</div> 
				<div className="food-row full-height text-center white d-none sm-block">
					<div className="food-box">  
						<Link to="/entree">
							<img src={EntreeImage} alt="entree" />
							<h2>Entree</h2>
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/burgers">
							<img src={BurgersImage} alt="burgers" />
							<h2>Burgers</h2> 
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/handheld">
							<img src={HandheldImage} alt="handheld" />
							<h2> HANDHELD </h2>
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/pizza">
							<img src={PizzaImage} alt="Pizza" />
							<h2>Pizza</h2> 
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/apps">
							<img src={AppsImage} alt="apps" />
							<h2>Apps</h2> 
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/sides">
							<img src={SidesImage} alt="sides" />
							<h2>Sides</h2> 
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/dessert">
							<img src={DessertImage}  alt="dessert" />
							<h2>Dessert</h2>
						</Link>
					</div>
					<div className="food-box"> 
						<Link to="/breakfast">
							<img src={BreakfastImage} alt="breakfast" />
							<h2 className="small">Breakfast</h2>
						</Link>
					</div>
				</div> 
				<div className="food-mobile-nav d-none sm-block">
					<nav className="d-flex align-items-center justify-content-center white">
						<Link to="/home">Home</Link>
						<Link to="/drinks">Drink</Link>
					</nav>	
				</div>
			</div>
		</main>
	);
}

export default EatPage;