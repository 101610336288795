import React from 'react';
import { Link } from "react-router-dom";
import MenuBar from '../components/Menubar';

type Props = { 
	user: any
};

const SettingsPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg faq-bg">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="faq-content mb-4 pb-4 f-15">  
						<div className="notification-settings">
							<h3 className="text-center lulo-bold mt-1"> NOTIFICATION SETTINGS </h3>
							<ul className="f-18 checkbox-list">
								<li> <input type="checkbox" id="notified1" /><label htmlFor ="notified1" >NOTIFIED WHEN NEW YOLOS ARE ADDED IN YOUR AREA.</label> </li>
								<li> <input type="checkbox" id="notified2" /><label htmlFor ="notified2" >NOTIFIED WHEN ONE OF YOUR YOLOS IS UPVOTED</label> </li>
								<li> <input type="checkbox" id="notified3" /><label htmlFor ="notified3" >NOTIFIED WHEN SOMEONE YOU ARE FOLLOWING ADDS A YOLO OR UPVOTE</label> </li>
							</ul>
						</div>	 
						<div className="change-identity text-center">
							<h3 className="lulo-bold mb-0"> SETTINGS </h3>
							<Link to="/change-identity"><b className="f-18 black"> CHANGE YOUR IDENTITY </b></Link>
						</div> 
					</div>
				</section> 
			</div>
		</main>
	);
}

export default SettingsPage;
