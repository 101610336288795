import React from 'react';

import ItemNew from './ItemNew';

type Props = { 
	name: String,
	items: any,
	user: any,
	editable: Boolean,
	handleLogin(): void
	handleUpvote(id): void,
	loadData(): void
	
};
const handleRemoveUpvote =async(id)=>{

}

const CategoryItems: React.FC<Props> = (props) => {

	const loadData = async () => {
		props.loadData()
	}

	const handleNotificationClick= async(id)=>{
	}

	return (
		<div>
			<h2 className="text-center lulo-bold f-28 mt-3 mb-3">{props.name} </h2>
			{
				props.items.length === 0	?
				<p className="text-center">No yolos.</p>
				:
				props.items.map((item, i) =>
					<ItemNew
						handleLogin={props.handleLogin}
						item={item}
						editable={props.editable}
						isLogged={props.user.isLogged}
						user={props.user} key={i}
						showRestaurant={true}
						handleUpvote={props.handleUpvote}
						handleRemoveUpvote={handleRemoveUpvote}
						handleNotificationClick={handleNotificationClick}
						loadData={loadData}
					restaurentPage={false}
				/>
				)
			}
		</div>			
	);
}

export default CategoryItems;
