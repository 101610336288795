import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";

import MenuBar from './../components/Menubar';
import User from './../components/Utils';
import LoaderContext from './../components/LoaderContext';

type Props = {
	user: User,
	handleLogin(user): void
};

const AdminPage: React.FC<Props> = (props) => {
	const [error, setError] = useState('');
	const { showLoader, hideLoader } = useContext(LoaderContext);

	let didCancel = false;

	const log = async (message: any) => {
		if (process.env.REACT_APP_LOG === 'true') {
			console.log(message);
		}
	}


	useEffect(() => {
		if (props.user.isLogged) {
		}

		return () => {
			didCancel = true;
		};
	}, [props.user.isLogged]);


	const history = useHistory();

	const onLoginAdmin = async () => {
		showLoader();
		fetch(process.env.REACT_APP_SERVER_URL + "/login/20", {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
				"Accept": "application/json",
				'Authorization': 'Bearer ' + props.user.token
			}),
		})
			.then(res => res.json())
			.then(
				(result) => {
					log(result);
					hideLoader();
					props.handleLogin({ id: result.id, name: result.name, token: result.token });
					history.push("home");
				},
				(error) => {
					log(error);
					setError(error.message);
					hideLoader();
				}
			)
	}

	if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
		return (
			<div>
				<div>Access denied.</div>
			</div>
		)
	} else {
		return (
			<main>
				<div className="container mypage">
					<MenuBar user={props.user} />
					<section>
						<div className="page-box-main">
							{props.user.isLogged && props.user.id === 514 &&
								<h2 className="futuralt-book pt-2 mb-4 text-center f-22">
									<button className="next-button" onClick={() => onLoginAdmin()}> ADMIN</button>
								</h2>
							}
							{error !== '' &&
								<p>{error}</p>
							}
							<div className="button-container">
								<button className="custom-button" onClick={() => history.push('/shareholders')}>SHAREHOLDERS</button>
							</div>
							<br />
							<div className="button-container">
								<button className="custom-button" onClick={() => history.push('/restaurent')}>RESTAURANTS</button>
							</div>
							<br />
							<div className="button-container">
								<button className="custom-button" onClick={() => history.push('/newMembers')}>NEW MEMBERS</button>
							</div>
							<br />
							<div className="button-container">
								<button className="custom-button" onClick={() => history.push('/recentYollos')}>RECENT YOLOS</button>
							</div>
							<br />
							<div className="button-container">
								<button className="custom-button" onClick={() => history.push('/markedClosedRestaurant')}>CLOSED RESTAURANTS</button>
							</div>
							<br />
							<div className="button-container">
								<button className="custom-button" onClick={() => history.push('/markedItemExpired')}>ITEMS NO LONGER AVAILABLE</button>
							</div>
						</div>
					</section>
				</div>
			</main>
		)
	}
}

export default AdminPage; 