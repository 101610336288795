import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import LoaderContext from '../components/LoaderContext';
import MenuBar from '../components/Menubar';
import { Link } from 'react-router-dom';
type Props = {
    user: User,
    handleLogin(user: any): void
};
const RecentYollosPage: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [items, setItems] = useState([]);
    const [yoloCount, setYoloCount] = useState(0);
    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());

    const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const selectedDate = new Date(value);
        setFrom(selectedDate);
    };

    const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const selectedDate = new Date(value);
        setTo(selectedDate);
    };

    const handleSearch = () => {
        loadData();
    };

    let didCancel = false;
    useEffect(() => {
        if (props.user.isLogged) {
            console.log("recent");

        }

        return () => {
            didCancel = true;
        };
    }, [props.user.isLogged]);
    const log = async (message: any) => {
        if (process.env.REACT_APP_LOG === 'true') {
            console.log(message);
        }
    }
    const handleErrors = (response) => {
        log(response);
        if (!response.ok) {
            var err = response.statusText;
            if (err === '') {
                err = 'Error';
            }
            throw Error(err);
        }
        return response;
    }

    const loadData = async () => {
        showLoader();
        const url = new URL(process.env.REACT_APP_SERVER_URL + "/recentYolos")

        if (from) {
            url.searchParams.append('from', from.toISOString().split('T')[0] );
        }
        if (to) {
            url.searchParams.append('to', to.toISOString().split('T')[0] );
        }

        fetch(url.toString(), {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    log(result);
                    if (!didCancel) {
                        hideLoader();
                        setItems(result.recentYolos);
                        setYoloCount(result.count);
                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };

    if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
        return (
            <div>
                <div>Access denied.</div>
            </div>
        )
    } else {
        return (
            <main>
                <div className="container mypage">
                    <MenuBar user={props.user} />
                    <section>
                        <div className="page-box-main">
                            {props.user.isLogged && props.user.id === 514 &&
                                <h2 className="futuralt-book pt-2 mb-4 text-center f-22">
                                    <button className="next-button"> Recent Yollos </button>
                                </h2>
                            }
                            {error !== '' &&
                                <p>{error}</p>
                            }
                            <div>
                                <div className="d-flex justify-content-space-between mb-10">
                                    <div className="d-flex dateInputGroup">
                                        <h2 style={{ paddingRight: 8 }}>From:</h2>
                                        <input
                                          type="date"
                                          value={from.toISOString().split('T')[0]}
                                          onChange={handleFromDateChange}
                                          max={new Date().toJSON().slice(0, 10)}
                                        />
                                    </div>
                                    <div className="d-flex dateInputGroup">
                                        <h2 style={{ paddingRight: 8 }}>To:</h2>
                                        <input
                                          type="date"
                                          value={to.toISOString().split('T')[0]}
                                          onChange={handleToDateChange}
                                          max={new Date().toJSON().slice(0, 10)}
                                        />
                                    </div>
                                    <button className="btn-primary" onClick={handleSearch}>Search</button>
                                </div>

                                <div className="table-container">
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%',marginTop:10,paddingBottom:10 }}>
                                        <h3>yolos within this time frame:</h3>
                                        <h2>'{yoloCount} { yoloCount > 0 ? 'yolos' : 'yolo' }'</h2>
                                    </div>
                                    <table className="shares-table">
                                        <thead>
                                        <tr>
                                            <td><strong>Name</strong></td>
                                            <td><strong>Category</strong></td>
                                            <td><strong>Menu Item</strong></td>
                                            <td><strong>Restaurant Name</strong></td>
                                            <td><strong>Email</strong></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.length <= 0 ? (
                                          <tr>
                                              <td colSpan={5} style={{ textAlign: 'center' }}>No items found</td>
                                          </tr>
                                        ) : (
                                          items.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item['Username']}</td>
                                                <td>{item['categoryName']}</td>
                                                <td>{item['itemName']}</td>
                                                <td>{item['restaurantName']}</td>
                                                <td>{item['email']}</td>
                                            </tr>
                                          ))
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <button className="next-button black">
                                        <Link to="/admin">Back</Link>
                                    </button>
                                </div>
                            </div>


                        </div>
                    </section>

                </div>
            </main>
        );
    }
}
export default RecentYollosPage;