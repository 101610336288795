import React from 'react';
import watch2 from '../images/appleWatch.jpg';
import { Link } from 'react-router-dom';
import DisclaimerPage from './DisclaimerPage';

type Props = {

}

const SecondSplashScreen: React.FC<Props> = (props) => {
  return(
    <section className="w-full splashMain2">
    <div className="flex splash-sub-main2">
      <div className="flex-1 d-flex flex-col mt-5pct ">
          <img className="door-dash" src={watch2} alt="doorDash" />
      </div>
      <div className="flex-2 mt-5pct pr-10pct" style={{ padding: "0 60px" }}>
        <p className="font-reklame" style={{ fontWeight: 'bold', fontSize: 29 }}>Tell us your favorite restaurant dish
          in the Twin Cities & be entered to win an Apple watch!</p>
        <p className="font-poppins text-14">Help us find the best food in MSP, promote local restaurants, and be entered
          to win an Apple watch! It’s a win / win!</p>
        <p className="font-reklame" style={{ color: '#cb581f', fontSize: 26, }}>How to enter: </p>
        <ul className="font-poppins text-14 ml-15">
          <li className="mb-10 list-disc">
            <Link className="underline" to="/add-yolo">Log into Yolomeal</Link>.-
            Super easy!
          </li>
          <li className="mb-10 list-disc">Select your city.
          </li>
          <li className="mb-10 list-disc">Follow the prompts to save your favorite!
          </li>
          <li className="mb-10 list-disc">Now you’re automatically entered to win an Apple watch!
          </li>
          <li className="mb-10 list-disc">BONUS: Add a photo of your favorite and receive 4 additional entries!
          </li>
        </ul>
        <p className="font-poppins disclaimer">This site is not part of the Facebook website or Facebook Inc.
          Additionally, this site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of META, Inc. For more info: <Link className="underline" to="/contest_disclaimer_and_conditions">Contest Conditions</Link> Contact us at: <a href="mailto:info@yolomeal.com" style={{color:'black'}} className='underline'>info@yolomeal.com</a></p>
      </div>
    </div>
    </section>
  );
}

export default SecondSplashScreen;