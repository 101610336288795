import React from 'react';
import watch from '../images/appleWatch.jpg'
import { Link } from 'react-router-dom';

type Props = {

}
const FirstSplashScreen: React.FC<Props> = (props) => {

  return(
    <section className="w-full splashMain d-flex align-items-center">
      <div className="flex splash-main-sub">
        <div className="flex-col pr-15pct">
            <p className="font-reklame mb-0" >YOLO MEAL</p>
            <div className="splash-pizza " >
              {/*<p>EAT & <br />DRINK</p>*/}
              {/*<p>EAT & <br />DRINK</p>*/}
              <img src={watch} width={240} alt="" className="splash-pizza-img" />
            </div>
            {/*<Link type="button" to="/add-yolo" className="splash-pizza-button">Get started!</Link>*/}
        </div>
        <div className="">
          <p className="font-reklame" style={{ fontFamily: 'poppins', fontWeight: 'bold' }}>Share your favorite menu
            item + be entered to win an Apple Watch!
          </p>
          {/*<p className="font-poppins text-14">Sick of wading through unhelpful Yelp reviews or sponsored posts you can’t trust?</p>*/}
          {/*<p className="font-poppins text-14">Tell us your favorite menu item from a local restaurant and you’re automatically entered to win a $200 Doordash gift card!</p>*/}
          <p className="font-reklame" style={{ color: '#cb581f', fontSize: 26, marginTop: 40 }}>How to enter: </p>
          <ul className="font-poppins text-14 ml-15">
            <li className="mb-10 list-disc">

              <Link className="underline" to="/add-yolo">Log into Yolomeal</Link>.-
              super easy!
            </li>
            <li className="mb-10 list-disc">Select your City
            </li>
            <li className="mb-10 list-disc">Follow the prompts to save your favorite!
            </li>
            {/*<li className="mb-10 list-disc">Write a few details about why you love this menu item and upload a photo of the dish - your photo or one you find of that item online is fine!*/}
            {/*</li>*/}
            <li className="mb-10 list-disc">Now you’re automatically entered to win an Apple watch!
            </li>
            <li className="mb-10 list-disc">BONUS: Add a photo of your favorite and receive 4 additional entries!
            </li>
          </ul>
          <p className="font-poppins text-14 font-bold" style={{ marginTop: 20, fontSize: 17 }}>Not only have you helped
            promote local restaurants, you’re making it easier for other people in your city to
            find amazing food!
          </p>
          <p className="font-poppins disclaimer">This site is not part of the Facebook website or Facebook Inc. Additionally, this site is NOT endorsed by
            Facebook in any way. FACEBOOK is a trademark of META, Inc.
            For more info: <Link className="underline" to="/contest_disclaimer_and_conditions">Contest Conditions</Link> Contact us at: <a href="mailto:info@yolomeal.com" style={{color:'black'}} className='underline'>info@yolomeal.com</a></p>
        </div>
      </div>
    </section>
  );
}

export default FirstSplashScreen;