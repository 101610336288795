import React, { useState, useEffect, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import MenuBar from './../components/Menubar';
import FindRestaurant from './../components/FindRestaurant';
import LoginPage from './LoginPage';
import User from './..//components/Utils';
import LoaderContext from './../components/LoaderContext';
import FindLocation from './../components/FindLocation';
import { tagArray } from '../utils/helpers';
import LoginPagev2 from './LoginPagev2';

type Props = {
  user: User;
  location: any;
  handleLogin(user): void;
  handleLogout(): void;
  handleLocationChange(obj): void;
};

const AddYoloPage: React.FC<Props> = (props) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [page, setPage] = useState('city');
  const [error, setError] = useState('');
  const [place, setPlace] = useState({ id: '', name: '', address: '', latitude: '', longitude: '', response: '' });
  const [category, setCategory] = useState({ id: '', name: '' });
  const [items, setItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [itemName, setItemName] = useState('');
  const [userName, setUserName] = useState('');
  const [tags, setTags] = useState<any[]>([]);
  const [itemId, setItemId] = useState(-1);
  const [itemTitle, setItemTitle] = useState('');
  const [description, setDescription] = useState('');
  const [leftCounter, setLeftCounter] = useState(300);
  const [showLocation, setShowLocation] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setPreview(URL.createObjectURL(file));
    },
  });
  const [yoloId, setYoloId] = useState(-1);
  let didCancel = false;
  const [rotate, setRotate] = useState(0);

  useEffect(() => {
    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    console.log(acceptedFiles)
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, [acceptedFiles]);

  const showCity = async () => {
    setPage('city');
  };

  const showName = async () => {
    if (page === 'city') {
      setPlace({ id: '', name: '', address: '', latitude: '', longitude: '', response: '' });
    }
    setPage('name');
  };

  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  };

  const showCategory = async () => {
    showLoader();
    fetch(process.env.REACT_APP_SERVER_URL + '/user-categories?user=' + props.user.id + '&v=' + Date.now(), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + props.user.token,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          log(result);
          setCategories(result);
          setError('');
          hideLoader();
          setPage('category');
        },
        (error) => {
          log(error);
          setError(error.message);
          hideLoader();
        }
      );
  };

  const showItem = async () => {
    setItemId(-1);
    if (page === 'category') {
      setItemName('');
    }
    setPage('item');
  };

  const checkItemName = async () => {
    showLoader();
    fetch(
      process.env.REACT_APP_SERVER_URL +
      '/restaurant-items?placeId=' +
      place.id +
      '&itemName=' +
      itemName.trim() +
      '&v=' +
      Date.now(),
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + props.user.token,
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.hasOwnProperty('error')) {
            //setError(result.error);
            setError(result.message);
            hideLoader();
          } else {
            setError('');
            hideLoader();
            if (hasTags(category.id)) {
              showTags();
            } else {
              showYolo();
            }
          }
        },
        (error) => {
          setError(error.message);
          hideLoader();
        }
      );
  };

  const showTags = async () => {
    if (page !== 'yolo') {
      setTags([]);
    }
    setPage('tags');
  };

  const showYolo = async () => {
    setDescription('');
    setUserName('');
    setLeftCounter(300);
    setPage('yolo');
  };

  const showUpload = async () => {
    setPage('upload');
  };

  const showDone = async () => {
    setPage('done');
  };

  const showLogin = async () =>{
    setPage('login');
  }

  const handlePlaceChanged = async (place) => {
    setError('');
    setPlace(place);
    showCategory();
  };

  const handlePlaceError = async (error) => {
    setError(error);
  };

  const hasTags = (id) => {
    const tags = [1, 2, 3, 4, 5, 6, 7, 13];
    return tags.includes(id);
  };

  const onItemClick = async (id, name) => {
    setItemId(id);
    setItemTitle(name);
    if (hasTags(category.id)) {
      showTags();
    } else {
      showYolo();
    }
  };

  const onTagClick = async (id) => {
    if (tags.includes(id)) {
      setTags(
        tags.filter(function (tag) {
          return tag !== id;
        })
      );
    } else {
      setTags([...tags, id]);
    }
  };

  const onItemNameChange = async (event) => {
    setItemName(event.target.value);
    setItemTitle(event.target.value);
  };

  const onUserNameChange = async (event) => {
    setUserName(event.target.value);
  };

  const onDescriptionChange = async (event) => {
    var str = event.target.value;
    setLeftCounter(300 - str.length);
    setDescription(str);
  };

  const handleLocationChanged = async (place) => {
    setShowLocation(false);
    props.handleLocationChange(place);
  };

  const handleHideLocation = async () => {
    setShowLocation(false);
  };

  const onCategoryClick = async (id, name) => {
    showLoader();
    fetch(
      process.env.REACT_APP_SERVER_URL +
      '/items?category=' +
      id +
      '&place=' +
      place.id +
      '&user=' +
      props.user.id +
      '&v=' +
      Date.now(),
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + props.user.token,
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          log(result);
          setItems(result);
          /*
				if (result.error != '') {
					setError(result.error);
				} else {
				}
				*/
          //props.handleLogin();
          setCategory({ id: id, name: name });
          showItem();
          setError('');
          hideLoader();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          log(error);
          setError(error.message);
          hideLoader();
        }
      );
  };

  const saveYolo = () => {
    showLoader();
    const data = {
      userId: props.user.id,
      placeId: place.id,
      placeName: place.name,
      placeAddress: place.address,
      placeLatitude: place.latitude,
      placeLongitude: place.longitude,
      placeResponse: place.response,
      categoryId: category.id,
      itemId: itemId,
      itemName: itemName.trim(),
      description: description,
      userName: userName,
      tags: tags,
    };
    log(data);
    fetch(process.env.REACT_APP_SERVER_URL + '/yolos', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + props.user.token,
      }),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          log(result);
          hideLoader();
          if (result.hasOwnProperty('error')) {
            //setError(result.error);
            setError(result.message);
          } else {
            setError('');
            setYoloId(result.id);
            showUpload();
          }
        },
        (error) => {
          setError(error.message);
          hideLoader();
          log(error);
        }
      );
  };

  const onUpload = async () => {
    if (!preview) {
      setError('No image to upload.');
      return;
    }

    showLoader();
    try {
      const rotatedBlob = await getRotatedImageBlob(preview, rotate);

      const formData = new FormData();
      formData.append('file', rotatedBlob, acceptedFiles[0].name);

      const result = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/yolo-image/${yoloId}/image/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      );

      if (!didCancel) {
        setError('');
        hideLoader();
        showDone();
      }
    } catch (err) {
      log(err);
      if (!didCancel) {
        hideLoader();
        setError(err.message || 'An error occurred');
      }
    }
  };

  const getRotatedImageBlob = (imageSrc: string, rotation: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        // Adjust canvas size and rotation
        if (rotation % 180 === 0) {
          canvas.width = img.width;
          canvas.height = img.height;
        } else {
          canvas.width = img.height;
          canvas.height = img.width;
        }

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to create Blob'));
          }
        }, 'image/jpeg');
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onLoginBackClick = async () => {};

  const itemList = items.map((item) => {
    console.log(item);

    if (item.used) {
      return (
        <li key={item.id}>
          <h4 className="bg-white f-22 light disabled">{item.name}</h4>
        </li>
      );
    } else {
      return (
        <li key={item.id} onClick={() => onItemClick(item.id, item.name)}>
          <h4 className="bg-white f-22 light">{item.name}</h4>
        </li>
      );
    }
  });

  const categoryList = categories.map((category) => {
    if (props.user.id !== 20 && category.counter === 3) {
      return (
        <li key={category.id}>
          <span className="category-button disabled">{category.name}</span>
        </li>
      );
    } else {
      return (
        <li key={category.id}>
          <span className="category-button" onClick={() => onCategoryClick(category.id, category.name)}>
            {category.name}
          </span>
        </li>
      );
    }
  });

  const getTagList = () => {
    return tagArray
      .filter((el) => el.category == Number(category.id))
      .map((tag) => {
        if (tags.includes(tag.id)) {
          return (
            <li key={tag.id}>
              <input type="checkbox" id={'tags' + tag.id} onClick={() => onTagClick(tag.id)} checked />
              <label htmlFor={'tags' + tag.id}>{tag.name}</label>
            </li>
          );
          //return <li key={tag.id}><span className="category-button" onClick={() => onTagClick(tag.id)}>{tag.name}</span></li>
        } else {
          return (
            <li key={tag.id}>
              <input type="checkbox" id={'tags' + tag.id} onClick={() => onTagClick(tag.id)} />
              <label htmlFor={'tags' + tag.id}>{tag.name}</label>
            </li>
          );
        }
      });
  };

  const onLoginSuccess = async () =>{
    setPage('yolo');
  }

  const rotateLeft = () => {
    setRotate((prevRotate) => prevRotate + 90);
  };

  const rotateRight = () => {
    setRotate((prevRotate) => prevRotate - 90);
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const customStyles = {
    content: {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      border: 0,
      background: '#f9c5b4',
    },
  };

    return (
      <main>
        <div className="container">
          <MenuBar user={props.user} />
          <section>
            {page === 'city' && (
              <div className="small-container add-yolo-info text-center">
                <div className="yolo-container text-center">
                  <h2 className="futuralt-book mt-4 pt-2 mb-2 text-left">
                    LET'S ADD ONE OF THE BEST OF YOUR BEST (A YOLO)!  IS YOUR RESTAURANT NEAR THIS CITY?
                  </h2>
                  <ul>
                    <li>
                      <h4 className="bg-white f-22 light	">{props.location.name}</h4>{' '}
                    </li>
                  </ul>
                  <h2 className="futuralt-book mt-4 mb-4 pt-2 pb-2 f-20"> IF NOT THEN.... </h2>
                  <ul>
                    <li>
                      <h4 className="bg-white f-22 light pointer" onClick={() => setShowLocation(true)}>
                        CHANGE LOCATION{' '}
                      </h4>
                    </li>
                  </ul>
                </div>
                <div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                  <h1>
                    <Link className="add-yolo-buttons" to="/home">BACK</Link>
                  </h1>
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={showName}>
                      NEXT
                    </button>
                  </h1>
                </div>
              </div>
            )}
            {page === 'name' && (
              <div className="small-container add-yolo-info text-center">
                <FindRestaurant
                  location={props.location}
                  handlePlaceChanged={handlePlaceChanged}
                  handlePlaceError={handlePlaceError}
                />
                {error !== '' && <p>{error}</p>}
                {place.name !== '' && <p>{place.name}</p>}
                <div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={showCity}>
                      BACK
                    </button>
                  </h1>
                  {place.name !== '' && (
                    <h1>
                      <button className="add-yolo-buttons next-button" onClick={showCategory}>
                        NEXT
                      </button>
                    </h1>
                  )}
                </div>
              </div>
            )}
            {page === 'category' && (
              <div className="small-container add-yolo-info text-center">
                <div className="yolo-container text-center">
                  <h2 className="futuralt-book mt-4 pt-2  "> {place.name} </h2>
                  <h2 className="futuralt-book   pt-2 mb-2  ">CHOOSE A CATEGORY.... </h2>
                </div>
                <ul className="category uppercase">{categoryList}</ul>
                {error !== '' && <p>{error}</p>}
                <div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={showName}>
                      BACK
                    </button>
                  </h1>
                </div>
              </div>
            )}
            {page === 'item' && (
              <div className="small-container add-yolo-info  ">
                <div className="yolo-container  ">
                  <h2 className="futuralt-book mt-4 pt-2 mb-4 text-center f-22"> {category.name} </h2>
                  {itemList.length > 0 && (
                    <>
                      <p className="text-center">These items below are already Favorited for this restaurant.If you wish to also add a Yolo for one of these items, just click the item text.</p>
                      <ul>{itemList}</ul>
                      <p className="text-center">Or add any new item name below.</p>
                    </>
                  )}
                  <input
                    autoFocus
                    id="item-name"
                    className="field xl-filed text-center mb-4"
                    placeholder="NAME OF MENU ITEM?"
                    value={itemName}
                    onChange={onItemNameChange}
                  />
                  {error !== '' && <p className="text-center">{error}</p>}
                </div>
                <div className="mt-0 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={showCategory}>
                      BACK
                    </button>
                  </h1>
                  {itemName.trim() !== '' && (
                    <h1>
                      <button className="add-yolo-buttons next-button" onClick={checkItemName}>
                        NEXT
                      </button>
                    </h1>
                  )}
                </div>
              </div>
            )}
            {page === 'tags' && (
              <div className="small-container add-yolo-info text-center notification-settings">
                <div className="yolo-container text-center">
                  <h2 className="futuralt-book mt-4 pt-2  ">{itemTitle}</h2>
                  <h2 className="futuralt-book pt-2 mb-2  ">CHECK ANY THAT APPLY</h2>
                </div>

                <ul className="f-18 checkbox-list">{getTagList()}</ul>

                <ul className="category uppercase"></ul>
                {error !== '' && <p>{error}</p>}
                <div className="mt-4 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={showItem}>
                      BACK
                    </button>
                  </h1>
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={showYolo}>
                      NEXT
                    </button>
                  </h1>
                </div>
              </div>
            )}
            {page === 'yolo' && (
              <div className="small-container add-yolo-info  ">
                <div className="yolo-container  ">
                  <h2 className="futuralt-book mt-1.3 pt-2 mb-1.5 text-center f-22"> {itemTitle}</h2>
                  <ul>
                    <li className="tell-us">
                      <textarea
                        autoFocus
                        maxLength={300}
                        rows={6}
                        placeholder="Tell us why you love it!"
                        onChange={onDescriptionChange}
                      >
                        {description}
                      </textarea>
                      <small>{leftCounter}</small>
                    </li>
                  </ul>
                  {props.user.isLogged && props.user.id === 20 && (
                    <input
                      className="field xl-filed text-center mt-4 mb-4"
                      placeholder="USER NAME"
                      value={userName}
                      onChange={onUserNameChange}
                    />
                  )}
                </div>
                <p className="text-center avenir f-22 mb-0.3 mt-1"> Or you can add later with an edit.</p>
                {error !== '' && <p>{error}</p>}
                <div className="mt-0 mb-0 d-flex black justify-content-space-between align-items-center uppercase">
                  {hasTags(category.id) && (
                    <h1>
                      <button className="add-yolo-buttons next-button" onClick={showTags}>
                        BACK
                      </button>
                    </h1>
                  )}
                  {!hasTags(category.id) && (
                    <h1>
                      <button className="add-yolo-buttons next-button" onClick={showItem}>
                        BACK
                      </button>
                    </h1>
                  )}
                  <h1>
                    <button className="add-yolo-buttons next-button" onClick={props.user.isLogged ? saveYolo : showLogin}>
                      SAVE YOLO
                    </button>
                  </h1>
                </div>
              </div>
            )}
            {page === 'upload' && (
              <div className="small-container add-yolo-info  ">
                <div className="yolo-container  ">
                  <h2 className="futuralt-book mt-2 mb-2 text-center f-22"> Yolo added. </h2>
                </div>
                {/*<h3 className="font-poppins text-center mb-2 f-18">*/}
                {/*  Add a photo of your favorite to receive 4 additional contest entries!*/}
                {/*</h3>*/}
                <h2 className="futuralt-book mt-2 mb-2 text-center f-22">
                  Add a photo now, or you can add it later by going to your My Page
                </h2>
                <h3 className="font-poppins text-center mb-2 f-18">
                  Tip: If you can’t take a photo, there is usually a photo of that menu item
                  to use by searching the restaurant photos on Yelp or Google reviews.
                </h3>
                <div className="add-photo-yolo d-flex justify-content-center">
                  {preview ? (
                    <div className="image-preview">
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ maxWidth: '100%', maxHeight: '300px', transform: `rotate(${rotate}deg)` }}
                      />
                      <button className="upload-btn upload-btn-rotate-right" onClick={rotateRight}>
                        ↷
                      </button>
                      <button className="upload-btn upload-btn-rotate-left" onClick={rotateLeft}>
                        ↶
                      </button>
                      <button className="upload-btn upload-btn-left" onClick={() => setPreview(null)}>
                        DON'T SAVE
                      </button>
                      <button className="upload-btn upload-btn-right" onClick={onUpload}>
                        SAVE
                      </button>
                    </div>
                  ) : (
                    <span className="add-photo white lulo-bold">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag photo here, or click to access photo files</p>
          </div>
        </span>
                  )}
                </div>
                <p className="text-center avenir f-22 mb-4 mt-4">{files}</p>
                {error !== '' && <p>{error}</p>}
                <div className="mt-0 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                  <h1>
                    <Link className="add-yolo-buttons" to="/home">
                      Home
                    </Link>
                  </h1>
                </div>
              </div>
            )}


            {page === 'login' && (
              <LoginPagev2
                emailVerified={false}
                user={props.user}
                handleLogin={props.handleLogin}
                showHome={false}
                handleBack={onLoginBackClick}
                success={onLoginSuccess}
            />
            )}
            {page === 'done' && <Redirect to="/my-page" />}
          </section>
        </div>
        <Modal isOpen={showLocation} ariaHideApp={false} style={customStyles}>
          <FindLocation
            location={props.location}
            handlePlaceChanged={handleLocationChanged}
            handleClose={handleHideLocation}
          />
        </Modal>
      </main>
    );
};

export default AddYoloPage;
