import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import LoaderContext from '../components/LoaderContext';
import MenuBar from '../components/Menubar';
import { Link } from 'react-router-dom';
import FindLocation from '../components/FindLocation';
import CityLocation from '../components/cityLocation';

type Props = {
    user: User,
    handleLogin(user: any): void
    location: any,
    handleLocationChange(obj): void,
};
const AdminRestorentPage: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const [location, setLocation] = useState({ lat: 0, lng: 0, name: '' });
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [restourents, setRestorents] = useState([]);
    const [showRestaurents, setShowRestaurents] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [showJumpToTopButton, setShowJumpToTopButton] = useState(false);
    // const google = window.google ? window.google : {}


    const handleScrol = () => {
        const windowHeight = window.innerHeight; // Height of the viewport
        const scrollPosition = window.scrollY; // Current scroll position
        const scrollThreshold = windowHeight * 1; // Threshold at 25% down the page

        setShowJumpToTopButton(scrollPosition > scrollThreshold); // Show button when scrolled past 25% down
    };

    // Function to handle clicking on "Jump to Top" button
    const handleJumpToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top of the page smoothly
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScrol);
        return () => {
            window.removeEventListener('scroll', handleScrol);
        };
    }, []);
    const log = async (message: any) => {
        if (process.env.REACT_APP_LOG === 'true') {
            console.log(message);
        }
    }
    const handleErrors = (response) => {
        log(response);
        if (!response.ok) {
            var err = response.statusText;
            if (err === '') {
                err = 'Error';
            }
            throw Error(err);
        }
        return response;
    }
    let didCancel = false;
    const loadRestourents = async (location) => {
        showLoader();
        fetch(process.env.REACT_APP_SERVER_URL + "/restaurantsByCity?city=" + location.name, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    hideLoader();
                    if (!didCancel) {
                        setRestorents(result.restaurants)
                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };
   
    useEffect(() => {
        if (props.user.isLogged) {
        }

        return () => {
            didCancel = true;
        };
    }, [props.user.isLogged]);

    const handlePlaceChanged = async (place) => {
        setShowLocation(false);
        props.handleLocationChange(place);
        loadRestourents(place);
        setShowRestaurents(true)
    };

    const handleHideLocation = async () => {
        setShowLocation(false)
    };


    if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
        return (
            <div>
                <div>Access denied.</div>
            </div>
        )
    } else {
        return (
            <main>
                <div className="container mypage">
                    <MenuBar user={props.user} />
                    <section>
                        <div className="page-box-main">
                            {props.user.isLogged && props.user.id === 514 &&
                                <h2 className="futuralt-book pt-2 mb-4 text-center f-22">
                                    <button className="next-button"> Restaurants </button>
                                </h2>
                            }
                            {error !== '' &&
                                <p>{error}</p>
                            }

                            <CityLocation location={props.location} handlePlaceChanged={handlePlaceChanged} handleClose={handleHideLocation} />
                            
                            {showRestaurents && restourents.length > 0 && (
                                <table className="shares-table" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <td><strong>Name</strong></td>
                                            <td><strong>Action</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {restourents.map((restaurant: any, index) => (
                                            <tr key={index}>
                                                <td>{restaurant.name}</td>
                                                <td>
                                                    <Link to={`/restaurentDetail/${restaurant.id}?name=${encodeURIComponent(restaurant.name)}`} className='select'>Select</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </section>
                    {showJumpToTopButton && (
                        <button className="jump-to-top-button" onClick={handleJumpToTop}>Jump to Top</button>
                    )}
                </div>
            </main>
        );
    }
}
export default AdminRestorentPage;
