import React from 'react';
import MenuBar from '../components/Menubar';

type Props = { 
	user: any
};

const PolicyPage: React.FC<Props> = (props) => {
	return (
		<main className="main-bg terms-bg">
			<div className="container">
				<MenuBar user={props.user}/>
				<section>
					<div className="about-content terms-content policy-content">  
						<div className="terms-box bg-white">
							<h3 className="text-center lulo-bold about-title">YOLO MEAL PRIVACY POLICY</h3>
							<p>
									Yolomeal.com recognizes that your privacy is very important, and we take it
									seriously. This Privacy Policy (“Privacy Policy”) describes our policies
									and procedures on the collection, use, disclosure, and sharing of your
									personal information when you use the Yolomeal Platform.
							</p>
							<p>
									This Privacy Policy applies to activities by Yolomeal, Inc. and its
									affiliates and subsidiaries (collectively “Yolomeal,” “we” or “us”).
							</p>
							<p>
									<strong>Notice for California Residents. </strong>
									If you are a California resident, please see the section below titled
									Additional Information for California Residents.
							</p>
							<p>
									<strong>The Information We Collect. </strong>
									We collect information directly from individuals, from third parties, and
									automatically through the Yolomeal Platform.
							</p>
							<p>
									<strong>Account and Profile Information. </strong>
									When you create an account and profile on the Yolomeal Platform, we
									collect your name, contact information, demographic information, and other
									information you provide, such as topics that you know about or find
									interesting. Your name, photo, and any other information that you choose to
									add to your public-facing profile will be available for viewing by the
									public and other users of the Yolomeal Platform. Once you create a profile,
									others will be able to see in your profile certain information about your
									activity on the Yolomeal Platform, such as the favorite foods you posted,
									your followers and who you follow.
							</p>
							<p>
									<strong>Your Content. </strong>
									We collect the information and content that you post to the Yolomeal
									Platform, including your photos and comments. Your content is publicly
									viewable on the Yolomeal Platform, along with your name. This also may be
									indexed by search engines and be republished elsewhere on the Internet in
									accordance with our Terms of Service.
							</p>
							<p>
									<strong>Communications. </strong>
									When you communicate with us (via email, through the Yolomeal Platform,
									or otherwise), we may maintain a record of your communication.
							</p>
							<p>
									<strong>Integrated Service Provider and Linked Networks. </strong>
									You can connect your existing Yolomeal account with certain third-party
									networks like Twitter or Google, for example (each a “Linked Network”). You
									can also elect to sign in or sign up to the Yolomeal Platform through a
									Linked Network. If you elect to sign up through or connect a Linked
									Network, we receive certain profile and account information about you from
									the Linked Network. These Linked Networks may also appear in your profile,
									so that people can find you on these Linked Networks. The specific
									information provided to us by Linked Networks is determined by you and such
									Linked Networks and may vary by network. In all cases, the permissions page
									for the Linked Network will describe the information being shared. You
									should consult their respective privacy policies for information about
									their practices. You may elect to use information from the Linked Network
									to populate your profile on the Yolomeal Platform or to import your
									contacts to help you find and connect with them on the Yolomeal Platform or
									to invite them to join. For information on your choices, including how to
									disconnect a Linked Network from your Yolomeal profile, see the Your
									Choices section below. You may also elect to connect and make and receive
									payments to and from use through third-party networks (“Integrated Service
									Provider”); if you do so, you will be allowing us to pass to and receive
									from the Integrated Service Provider your login information and other user
									data for payment purposes.
							</p>
							<p>
									<strong> Automatically Collected Information About Your Activity. </strong>
									We use cookies, log files, pixel tags, local storage objects, and other
									tracking technologies to automatically collect information about your
									activities, such as your searches, page views, date and time of your visit,
									and other information about your use of the Yolomeal Platform. We also
									collect information that your computer or mobile device provides to us in
									connection with your use of the Yolomeal Platform such as your browser
									type, type of computer or mobile device, browser language, IP address,
									mobile carrier, unique device identifier, location, and requested and
									referring URLs. We also receive information when you view content on or
									otherwise interact with the Yolomeal Platform, even if you have not created
									an account. For more information, see the “Cookies, Pixels and Tracking”
									section below and our Cookie Policy.
							</p>
							<p>
									<strong> Engagement. </strong>
									We collect browsing information – such as IP address and location, date
									and time stamp, user agent, Yolomeal cookie ID (if applicable), URL, unique
									advertising or content identifiers (if applicable) and time zone, and other
									information about user activities on the Yolomeal Platform, as well as on
									third-party sites and services that have embedded our Yolomeal pixels
									(“Pixels”), widgets, plug-ins, buttons, or related services. See the
									section below about Yolomeal Ads and Personalization for more detailed
									information about how our Pixels may be used by publishers or users of our
									advertising services (“Ad Services”) on the Yolomeal Platform to enable
									personalization, as well as your choices related to advertising and
									personalization. We may also receive information about you from third
									parties, such as other users, partners (including ad partners), or our
									affiliated companies.
							</p>
							<p>
									<strong>How We Use Your Information. </strong>
									We do not sell your personal information – such as your name and contact
									information – to third parties to use for their own marketing purposes.
									Yolomeal uses the information we collect for the following purposes:
							</p>
							<ul>
									<li>
								<strong>Provide our Services. </strong>To provide you the services we offer on the
									Yolomeal Platform and make the Yolomeal Platform available to the public,
									communicate with you about your use of the Yolomeal Platform, respond to
									your inquiries, provide troubleshooting, and for other customer service
									purposes.
							</li>
									<li>
								<strong>Personalization. </strong>To tailor the content and information that we may send
									or display to you in the Yolomeal Platform, to suggest followers and
									content, to help others find your profile, to offer location customization,
									and personalized help and instructions, and to otherwise personalize your
									experiences while using the Yolomeal Platform.
							</li>
									<li>
								<strong>Advertising. </strong>To display interest-based advertising to you in the Yolomeal
									Platform, to improve our advertising and measurement systems so we can show
									you relevant ads, to pre-fill forms in ads, and to measure the
									effectiveness and reach of ads and services. For more information, see the
									Ad Services section below about Yolomeal Ads and Personalization.
							</li>
									<li>
								<strong>Marketing and Promotions. </strong>For marketing and promotional purposes, such as
									to send you news and newsletters, special offers, and promotions, or to
									otherwise contact you about products or information we think may interest
									you, including information about third-party products and services.
							</li>
									<li>
								<strong>Analytics. </strong>To gather metrics to better understand how users access and
									use the Yolomeal Platform; to evaluate and improve the Yolomeal Platform,
									including the Ad Services and personalization, and to develop new products
									and services.
							</li>
									<li>
								<strong>Comply with Law. </strong>To comply with legal obligations, as part of our general
									business operations, and for other business administration purposes.
							</li>
									<li>
								<strong>Prevent Misuse. </strong>Where we believe necessary to investigate, prevent or
									take action regarding illegal activities, suspected fraud, situations
									involving potential threats to the safety of any person or violations of
									our Terms of Service or this Privacy Policy.
									</li>
							</ul>
							<p>
									<strong>How We Share Your Information </strong>
							</p>
							<p>
									We share information as set forth below, and where individuals have
									otherwise consented:
							</p>
							<ul>
									<li>
									<strong>Service Providers. </strong>
									We may share your information with third-party service providers who use
									this information to perform services for us, such as payment processors,
									hosting providers, auditors, advisors, consultants, customer service and
									support providers, as well as those who assist us in providing the Ad
									Services.
							</li>
									<li>
									<strong>Affiliates. </strong>
									The information collected about you may be accessed by or shared with
									subsidiaries and affiliates of Yolomeal, Inc., whose use and disclosure of
									your personal information is subject to this Privacy Policy.
							</li>
									<li>
									<strong>Business Transfers. </strong>
									We may disclose or transfer information, including personal information,
									as part of any merger, sale, and transfer of our assets, acquisition or
									restructuring of all or part of our business, bankruptcy, or similar event.
							</li>
									<li>
									<strong>Legally Required. </strong>
									We may disclose your information if we are required to do so by law.
							</li>
									<li>
									<strong>Protection of Rights. </strong>
									We may disclose information where we believe it necessary to respond to
									claims asserted against us or, comply with legal process (e.g., subpoenas
									or warrants), enforce or administer our agreements and terms, for fraud
									prevention, risk assessment, investigation, and protect the rights,
									property or safety of Yolomeal, its users, or others.
									</li>
							</ul>
							<p>
									<strong>Your Content and Public Information. </strong>
									Your content, including your name, profile picture, profile information,
									and certain associated activity information is available to other users of
									the Yolomeal Platform and may also be viewed publicly. Public viewing
									includes availability to non-registered visitors and can occur when users
									share your content across other sites or services. In addition, your
									content may be indexed by search engines. In some cases, we may charge for
									access to your content and public information on the Yolomeal Platform. See
									the section below about Your Choices for information about how you may
									change how certain information is shared or viewed by others.
							</p>
							<p>
									<strong>Metrics. </strong>
									We may share with our advertisers or publishers aggregate statistics,
									metrics and other reports about the performance of their ads or content in
									the Yolomeal Platform such as the number of unique user views, demographics
									about the users who saw their ads or content, conversion rates, and date
									and time information. We do not share IP addresses or personal information,
									but certain features may allow you to share your personal information with
									advertisers on our platform if you choose to do so. We may also allow our
									advertisers or publishers to use Pixels on the Yolomeal Platform in order
									to collect information about the performance of their ads or content.
							</p>
							<p>
									<strong>Anonymized and Aggregated Data. </strong>
									We may share aggregated or de-identified information with third parties
									for research, marketing, analytics and other purposes, provided such
									information does not identify a particular individual.
							</p>
							<p>
									<strong>As Directed by You. </strong>
									We may also share data as otherwise directed by you (for example, to
									fulfill a transaction).
							</p>
							<p>
									<strong>Cookies, Pixels and Tracking. </strong>
									We and our third-party providers use cookies, clear GIFs/pixel tags,
									JavaScript, local storage, log files, and other mechanisms to automatically
									collect and record information about your usage and browsing activities on
									the Yolomeal Platform and across third-party sites or online services. We
									may combine this information with other information we collect about users.
									Below, we provide a brief summary of these activities:
							</p>
							<ul>
									<li>
											<strong>Cookies. </strong>
											These are small files with a unique identifier that are transferred
											to your browser through our websites. They allow us to remember users
											who are logged in, to understand how users navigate through and use the
											Yolomeal Platform, and to display personalized content and targeted ads
											(including on third-party sites and applications).
									</li>
									<li>
											<strong>Pixels, web beacons, clear GIFs. </strong>
											These are tiny graphics with a unique identifier, similar in function
											to cookies, which we use to track the online movements of users of the
											Yolomeal Platform and the web pages of users of our Ad Services, and to
											personalize content. We also use these in our emails to let us know
											when they have been opened or forwarded, so we can gauge the
											effectiveness of our communications.
									</li>
									<li>
											<strong>Analytics Tools. </strong>
										We may use internal and third-party analytics tools, including        <em>Google Analytics</em>. The third-party analytics companies we work
											with may combine the information collected with other information they
											have independently collected from other websites and/or other online
											products and services. Their collection and use of information is
											subject to their own privacy policies.
									</li>
							</ul>
							<p>
									<strong>Do-Not-Track Signals. </strong>
									Please note we do not change system behavior within the Yolomeal Platform
									in response to browser requests not to be tracked. You may, however,
									disable certain tracking by third parties as discussed in the Yolomeal Ads
									and Personalization section below. You may also opt out of tracking by
									Yolomeal Pixels, as described below in Yolomeal Ads and Personalization.
							</p>
							<p>
									<strong>Yolomeal Ads and Personalization. </strong>
									We may display personalized content (including from third-party content
									publishers) and personalized ads (including sponsored content), based on
									information that we have collected via the Yolomeal Platform, and through
									our Pixels, widgets, and buttons embedded on third-party sites. We also may
									report aggregated or de-identified information about the number of users
									that saw a particular ad or content and related audience engagement
									information to users of our Ad Services and to publishers of content on the
									Yolomeal Platform.
							</p>
							<p>
									Advertisers who use our Ad Services may also provide us with information as
									part of their ad campaigns, including customer information (e.g., email
									addresses, phone numbers, or other contact information, demographic or
									interest data) in order to create custom audiences for personalizing their
									ad campaigns or for measuring the effectiveness of their ads; we only use
									this information to facilitate the particular advertiser’s campaign
									(including ad metrics and reporting to that advertiser); and we do not
									disclose this information to third parties (other than our service
									providers) unless required by law. We also do not disclose to the
									advertisers who use our Ad Services the names or contact information of
									their customers that were successfully reached as part of such campaigns
									without those customers’ consent.
							</p>
							<p>
									<strong>Third-Party Ads on Yolomeal. </strong>
									As described in our Cookie Policy we may also work with third parties such
									as network advertisers to serve ads on the Yolomeal Platform and on
									third-party websites or other media (e.g., social networking platforms),
									such as Google AdSense and Facebook Audience Network. These third parties
									may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs
									and other tracking technologies to measure the effectiveness of their ads
									and to personalize advertising content to you.
							</p>
							<p>
									In addition to opting out of tracking across sites by our Pixels (see Your
									Choices section below), you also may opt out of much interest-based
									advertising on third-party sites and through third-party ad networks
									(including Facebook Audience Network and Google AdSense). See Your Choices
									section below for more information about opting out of third-party ad
									networks.
							</p>
							<p>
									<strong>How We Protect Your Information. </strong>
									The security of your information is important to us. Yolomeal has
									implemented safeguards to protect the information we collect. However, no
									website or Internet transmission is completely secure. We urge you to take
									steps to keep your personal information safe, such as choosing a strong
									password and keeping it private, as well as logging out of your user
									account, and closing your web browser when finished using the Yolomeal
									Platform on a shared or unsecured device.
							</p>
							<p>
									<strong>Access and Amend Your Information. </strong>
									You may update or correct your account information at any time by logging
									in to your account. You may also make a number of other adjustments to
									settings or the display of information about you as described in more
									detail in the following section about Your Choices.
							</p>
							<p>
									<strong>Your Choices. </strong>
									You may, of course, decline to submit information through the Yolomeal
									Platform, in which case we may not be able to provide certain services to
									you. You may also control the types of notifications and communications we
									send, limit the information shared within the Yolomeal Platform about you,
									and otherwise amend certain privacy settings. Here is some further
									information about some of your choices.
							</p>
							<p>
									<strong>Your Content. </strong>
									You may edit or delete the Yolos that you post at any time. Any deleted
									content will be removed from third-party sites from which it has been
									shared via Yolomeal’s standard sharing features; however we have no control
									over deletions or changes to your content if it has been shared manually by
									others.
							</p>
							<p>
									<strong>Emails and Notifications. </strong>
									<strong>. </strong>
									When you join the Yolomeal Platform by signing up for an account or
									creating a profile, as part of the service, you will receive email digests
									containing content from the Yolomeal Platform that we believe may match
									your interests. You may also receive notices about other content from the
									Yolomeal Platform that may be of interest to you, such as people that you
									follow or that match your interests. In addition, users may be able to send
									communications and invites to their imported contacts through their
									Yolomeal account, by email, text, or phone. You can manage all your email
									and notice preferences in your account profile settings, under your Emails
									and Notifications settings. If you opt out of receiving emails or other
									notifications about recommendations or other information we think may
									interest you, we may still send you transactional emails about your account
									or any services you have requested or received from us.
							</p>
							<p>
									<strong>Linked Networks. </strong>
									You may connect or disconnect your Linked Networks, such as Google, through
									the Account Settings tab in your profile settings, and you may access,
									amend and delete much of your profile information through your profile
									settings. Once you disconnect a Linked Network, we will not receive
									information from that Linked Network going forward unless you choose to
									reconnect it. You may also control whether the Linked Network is visible in
									your profile and, if you enable contact imports, how you message your
									contacts to find and connect with you on the Yolomeal Platform.
							</p>
							<p>
									<strong>Securing data. </strong>
									Yolomeal is headquartered in the United States, and has operations,
									entities, and service providers in the United States and throughout the
									world. As such, we and our service providers may transfer your personal
									information to, or access it in, jurisdictions (including the United
									States) that may not provide equivalent levels of data protection as your
									home jurisdiction. We will take steps to ensure that your personal
									information receives an adequate level of protection in the jurisdictions
									in which we process it.
							</p>
							<p>
									<strong>Children’s Privacy. </strong>
									We do not knowingly collect or solicit personal information from anyone
									under the age of 13 or knowingly allow such persons to register. If we
									become aware that we have collected personal information from a child under
									the relevant age without parental consent, we take steps to delete that
									information.
							</p>
							<p>
									<strong>Links to Other Websites. </strong>
									The Yolomeal Platform may contain links to third-party sites or online
									services. We are not responsible for the practices of such third parties,
									whose information practices are subject to their own policies and
									procedures, not to this Privacy Policy.
							</p>
							<p>
									<strong>Additional Information for California Residents. </strong>
									This section provides information organized in accordance with the
									California Consumer Privacy Act (“CCPA”) for residents of California about
									how we handle certain personal information we have collected over the past
									12 months.
							</p>
							<p>
									<strong>Categories of Personal Information. </strong>
									This Privacy Policy discloses the categories of personal information that
									we collect, use, and disclose in order to operate our business over the
									past 12 months:
							</p>
							<ul>
									<li>
											<strong>Collect. </strong>
									The section above entitled        <strong>The Information We Collect</strong> discloses the categories of
											personal information that we collect, which include your account and
											profile information, your content, your communications with us, your
											information you provide from linked networks, information about your
											activity on Yolomeal, and how you engage with Yolomeal.
									</li>
									<li>
											<strong>Use. </strong>
									The section above entitled        <strong>How We Use Your Information</strong> discloses the that the
											categories of personal information we collect are used to provide our
											services, for personalization, to provide advertising in order to
											generate income to operate Yolomeal, to promote our services to you, to
											analyze how our services are used, to comply with law, and to prevent
											misuse of Yolomeal.
									</li>
									<li>
											<strong>Disclose. </strong>
									The section above entitled        <strong>How We Share Your Information</strong> discloses that we share
											your personal information with, service providers, affiliates, law
											enforcement authorities, and as needed with third parties to provide
											our services.
									</li>
							</ul>
							<p>
									<strong>We do not sell your personal information to third parties. </strong>
									Yolomeal may share your personal information with third parties and third
									parties may collect your personal information as described above in the How
									We Share Your Information section. In addition, as described in our Cookie
									Policy, we may also work with third parties such as network advertisers to
									serve ads on the Yolomeal Platform and on third-party websites or other
									media (e.g., social networking platforms), such as Google AdSense and
									Facebook Audience Network. These third parties may use cookies, JavaScript,
									web beacons (including clear GIFs), Flash LSOs and other tracking
									technologies to measure the effectiveness of their ads and to personalize
									advertising content to you.
							</p>
							<p>
									We do not collect personal information from consumers that we know are
									younger than 16 years old.
							</p>
							<p>
									<strong>Requests for Deletion, Copy and Right to Know Your Information. </strong>
									California consumers have the right to make the following requests, which
									we endeavor to honor from non-California residents as well:
							</p>
							<ul>
									<li>
											<strong>Deletion. </strong>
											You have the right to request deletion of personal information that
											we have collected about you.
									</li>
									<li>
											<strong>Copy and Right to Know. </strong>
											You have the right to request a copy of the specific pieces of
											personal information that we have collected about you over the past 12
											months, including the categories of information, sources, and purposes
											of collection, as well as categories of third parties we have shared it
											with.
									</li>
									<li>
											<strong>Designated Agent. </strong>
											You may designate an agent to make a request on your behalf. That
											agent must have access to your account in order for us to verify the
											request.
									</li>
							</ul>
							<p>
									You may submit deletion, copy and right to know requests by emailing us at
									<a href="mailto:privacy@yolomeal.com">privacy@yolomeal.com</a>. You will need access to the email account associated
									with your Yolomeal account in order to verify your identity and complete
									this process.
							</p>
							<p>
									<strong>Non-Discrimination. </strong>
									Yolomeal will not discriminate against you, including by denying or
									providing a different level or quality of goods or services should you
									choose to exercise your options under the CCPA.
							</p>
							<p>
									<strong> Your California Privacy Rights under California’s Shine-the-Light
													Law. </strong>
									California law permits users who are California residents to request and
									obtain from us once a year, free of charge, a list of the third parties to
									whom we have disclosed their personal information (if any) for their direct
									marketing purposes in the prior calendar year, as well as the types of
									personal information disclosed to those parties. If you are a California
									resident and would like to request this information, please submit your
									request in an email to <a href="mailto:privacy@yolomeal.com">privacy@yolomeal.com</a>.
							</p>
							<p>
									<strong>Contact Us. </strong>
									If you have any questions about our practices or this Privacy Policy,
									please contact us at <a href="mailto:privacy@yolomeal.com">privacy@yolomeal.com</a>.
							</p>
							<p>
									<strong>Changes to Our Privacy Policy. </strong>
									If we change our privacy policies and procedures, we will post those
									changes on this page. If we make any changes to this Privacy Policy that
									materially change how we treat your personal information, we will endeavor
									to provide you with reasonable notice of such changes, such as via
									prominent notice in the Yolomeal Platform or to your email address of
									record, and where required by law, we will obtain your consent or give you
									the opportunity to opt out of such changes.
							</p>
						</div>
					</div> 
				</section> 
			</div>
		</main>
	);
}

export default PolicyPage;