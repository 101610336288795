import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Simulate } from 'react-dom/test-utils';

type Props = {
  isOpen: boolean;
  oldImage: any;
  handleModalClose(): void;
  user: any
  yoloId: number
  files: any
  onUpload(): void
  error: any
  getRootProps(obj): any
  getInputProps(): any
  acceptedFiles: any
};

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  },
};

const UploadPhotoModal: React.FC<Props> = (props) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [rotate, setRotate] = useState(0);
  const [rotateOld, setRotateOld] = useState(0);

  useEffect(() => {
    if (props.acceptedFiles.length > 0) {
      const file = props.acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, [props.acceptedFiles]);

  const closeModel = ()=>{
    props.handleModalClose();
  }

  const onUpload = async () => {
    if (!preview) {
      fetch(process.env.REACT_APP_SERVER_URL+"/yolo-image/"+ props.oldImage)
        .then(res=> res.blob())
        .then(blob => {
          var file = new File([blob], "image.jpg", {
            type: blob.type
          });
          const reader = new FileReader();
          reader.onloadend = () =>{
            getRotatedImageBlob(reader.result as string, rotateOld)
              .then(rotatedBlob =>{
                file = new File([rotatedBlob], "image.jpg",{
                  type: rotatedBlob.type
                })
                props.acceptedFiles[0] = file;
                props.onUpload()
              }).catch(err => console.log(err));
          }
          reader.readAsDataURL(file);
        })
        .catch(error => console.log(error))
    }
    else {
      var rotatedBlob = await getRotatedImageBlob(preview, rotate);
      var file = new File([rotatedBlob], props.acceptedFiles[0].name, {
        type: props.acceptedFiles[0].type,
      });
      props.acceptedFiles[0] = file;
      props.onUpload()
      setPreview(null)
    }
  }

  const rotateLeft = () => {
    setRotate(rotate + 90);
  }

  const rotateRight = () => {
    setRotate(rotate - 90);
  }

  const rotateLeftOld = () => {
    setRotateOld(rotateOld + 90);
  }

  const rotateRightOld = () => {
    setRotateOld(rotateOld - 90);
  }

  const getRotatedImageBlob = (imageSrc: string, rotation: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        // Adjust canvas size and rotation
        if (rotation % 180 === 0) {
          canvas.width = img.width;
          canvas.height = img.height;
        } else {
          canvas.width = img.height;
          canvas.height = img.width;
        }

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to create Blob'));
          }
        }, 'image/jpeg');
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Modal isOpen={props.isOpen} ariaHideApp={false} style={customStyles}>
      <div className="container bg-orange">
        <section>
          {props.oldImage && (
            <div>
              <h2 className="futuralt-book mt-4 pt-2 mb-4 text-center f-22"> EDIT OLD PHOTO </h2>
              <div className="mt-4 add-photo-yolo d-flex justify-content-center text-center w-full">
                <div className="image-preview">
                  <img
                    src={process.env.REACT_APP_SERVER_URL + '/yolo-image/' + props.oldImage}
                    alt="Preview"
                    id={"oldImage"}
                    style={{ maxWidth: '100%', maxHeight: '300px', transform: `rotate(${rotateOld}deg)` }}
                  />
                  <button className="upload-btn upload-btn-rotate-right" onClick={rotateRightOld}>↶</button>
                  <button className="upload-btn upload-btn-rotate-left" onClick={rotateLeftOld}>↷</button>
                  <button className="upload-btn upload-btn-left" onClick={() => setPreview(null)}>DON'T SAVE</button>
                  <button className="upload-btn upload-btn-right" onClick={() => onUpload()}>SAVE</button>
                </div>
              </div>
            </div>
          )}
    <div className="small-container add-yolo-info  ">
      <div className="yolo-container  ">
        <h2 className="futuralt-book mt-4 pt-2 mb-4 text-center f-22"> OR UPLOAD A NEW ONE </h2>
        <div className="add-photo-yolo d-flex justify-content-center">
          {preview ? (
            <div className="image-preview d-flex">
              <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px', transform: `rotate(${rotate}deg)` }} />
              <button className="upload-btn upload-btn-rotate-right" onClick={() => rotateRight()}>↶</button>
              <button className="upload-btn upload-btn-rotate-left" onClick={() => rotateLeft()}> ↷</button>
              <button className="upload-btn upload-btn-left" onClick={() => setPreview(null)}>DON'T SAVE
              </button>
              <button className="upload-btn upload-btn-right" onClick={() => onUpload()}>SAVE</button>
            </div>
            ) :
            <span className="add-photo white lulo-bold">
              <div {...props.getRootProps({ className: 'dropzone' })}>
                <input {...props.getInputProps()} />
                <p>Drag photo here, or click to access photo files</p>
              </div>
            </span>
          }
                </div>
                <p className="text-center avenir f-22 mb-4 mt-4">{props.files}</p>
                {props.error !== '' &&
                  <p>{props.error}</p>
                }
              </div>
              <div className="mt-0 mb-4 d-flex black justify-content-space-between align-items-center uppercase">
                <h1>
                  <button className="next-button" onClick={closeModel}>BACK</button>
                </h1>
              </div>
            </div>
        </section>
      </div>
    </Modal>
)
}

export default UploadPhotoModal;